"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Icon_1 = require("../Icon");
var PasswordToggleInput = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, onBlur = _a.onBlur, hasError = _a.hasError, rest = __rest(_a, ["className", "onBlur", "hasError"]);
    var _c = React.useState(false), visible = _c[0], setVisible = _c[1];
    var _d = React.useState(false), inputActive = _d[0], setInputActive = _d[1];
    return (React.createElement("div", { className: "sui-flex sui-items-center sui-border sui-border-solid sui-rounded sui-pl-1 sui-pr-2 sui-py-2 sui-h-auto sui-font-size-5\n      ".concat(inputActive && !hasError ? 'sui-border-blue-5' : '', "\n      ").concat(hasError ? 'u-borderNegative4' : 'sui-border-gray-5', "\n      ").concat(className), "data-testid": "password-toggle-input", style: { maxHeight: 48 } },
        React.createElement("input", __assign({ className: "sui-w-full sui-border-0 sui-font-size-5" }, rest, { type: visible ? 'text' : 'password', style: { outline: 'none' }, onFocus: function () { return setInputActive(!inputActive); }, onBlur: function (e) {
                setInputActive(!inputActive);
                if (onBlur) {
                    onBlur(e);
                }
            } })),
        React.createElement("div", { onKeyDown: function () { }, onClick: function () { return setVisible(!visible); }, role: "button", tabIndex: 0 },
            React.createElement(Icon_1.Icon, { name: visible ? 'visibility-off' : 'visibility' }))));
};
exports.default = PasswordToggleInput;
