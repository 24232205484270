import { getFormattedAmount } from 'frontend-toolkit';
import { useInstallmentStateSelector } from 'state/installments/installmentsSlice';
import { usePaymentStateSelector } from 'state/payment/paymentSlice';

export const useInstallmentHelpers = () => {
  const installments = useInstallmentStateSelector();
  const paymentData = usePaymentStateSelector();
  const unpaid = installments?.filter((installment) => installment.status !== 'paid');
  const installmentsLeft = unpaid?.length;
  const isInstallmentPayment = unpaid && unpaid?.length > 0;
  const todaysPayment = paymentData ? getFormattedAmount(paymentData.amount / 100) : undefined;

  return {
    installmentsLeft,
    todaysPayment,
    isInstallmentPayment,
  };
};
