import { getFormattedAmount } from 'frontend-toolkit';
import React from 'react';
import { useOrderStateSelector } from 'state/order/orderSlice';
import { usePaymentStateSelector } from 'state/payment/paymentSlice';

export type PaymentTypeOption = 'card' | 'us_bank_account'; // the values from stripe form

export const usePaymentHelpers = (paymentOption?: string | null) => {
  const [showFee, setShowFee] = React.useState<boolean>(false);
  const [showAddonTotal, setShowAddonTotal] = React.useState<boolean>(false);
  const paymentData = usePaymentStateSelector();
  const orderData = useOrderStateSelector();

  const getAddonTotal = React.useCallback(() => {
    if (!paymentOption) {
      // remove line items related to add-ons for the org total
      return orderData?.orderLineItems.reduce(
        (accumulator, lineItem) => lineItem.type === 'partner_offering' ? accumulator + lineItem.amount : accumulator,
        0,
      ) || 0;
    }
    return 0;
  }, [orderData?.orderLineItems, paymentOption])

  React.useEffect(() => {
    if (!paymentData?.paymentAccountPaysProcessingFee) {
      setShowFee(true);
    }

    if (getAddonTotal() > 0) {
      setShowAddonTotal(true);
    }
  }, [paymentData, orderData, getAddonTotal]);

  const getFormattedAmounts = React.useCallback(
    (paymentType?: PaymentTypeOption) => {
      const fee = paymentType === 'us_bank_account' ? paymentData?.achFee || 0 : paymentData?.creditCardFee || 0;
      if (paymentData?.amount != null) {
        return {
          total: getFormattedAmount((+paymentData?.amount + fee + getAddonTotal()) / 100),
          orgSubTotal: getFormattedAmount((+paymentData?.amount) / 100),
          orgTotal: getFormattedAmount((+paymentData?.amount + fee) / 100),
          addonTotal: getFormattedAmount(getAddonTotal() / 100),
          fee: getFormattedAmount(fee / 100)
        };
      }
    },
    [paymentData?.amount, paymentData?.achFee, paymentData?.creditCardFee, getAddonTotal]
  );

  return {
    getFormattedAmounts,
    showFee,
    showAddonTotal
  };
};
