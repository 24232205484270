/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuthResult = {
    success: boolean;
    token?: string;
    error?: AuthResult.error;
};

export namespace AuthResult {

    export enum error {
        INVALID = 'invalid',
        UNKNOWN = 'unknown',
    }


}

