"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassicService = void 0;
/**
 * ClassicService is a service that helps pull information from classic for applications
 * that are mounted inside of TeamSnap's classic rails application.
 *
 * This class is safe to use outside of classic, although nothing will work (but it shouldnt break either);
 */
var ClassicService = /** @class */ (function () {
    function ClassicService() {
    }
    ClassicService.getWindowValue = function (key) {
        var _a;
        return ((_a = window.TeamSnap) === null || _a === void 0 ? void 0 : _a[key]) || null;
    };
    ClassicService.getApiV3URL = function () {
        return this.getWindowValue('apiv3Url');
    };
    ClassicService.getFeatureRollouts = function () {
        return this.getWindowValue('featureRollouts');
    };
    ClassicService.isRolloutOn = function (rolloutKey) {
        var rollouts = this.getFeatureRollouts();
        return (Object.keys(rollouts)
            .filter(function (key) { return key === rolloutKey; })
            .map(function (key) { return rollouts[key]; })[0] || false);
    };
    ClassicService.getTinyMCEKey = function () {
        return this.getWindowValue('tinyMceKey');
    };
    return ClassicService;
}());
exports.ClassicService = ClassicService;
