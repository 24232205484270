/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentOption } from '../models/InstallmentOption';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstallmentsService {

    /**
     * Preview the available installment plan options given order id
     * @param id
     * @returns InstallmentOption Success
     * @throws ApiError
     */
    public static previewInstallmentPlanOptions(
        id: number,
    ): CancelablePromise<InstallmentOption> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{id}/preview-installment-plan-options',
            path: {
                'id': id,
            },
        });
    }

}
