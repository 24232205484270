/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { PaymentConfirmation } from 'components/Confirmation/PaymentConfirmation';
import { OrderConfirmation } from 'components/Confirmation/OrderConfirmation';
import {
  getOrderConfirmation,
  useFormIdSelector,
  useConfirmationLoadedSelector,
  useOrderStateSelector,
} from 'state/order/orderSlice';
import { useAppDispatch } from 'state/hooks';
import { usePaymentResultStateSelector } from 'state/payment/paymentSlice';

export const ConfirmationPage = () => {
  const order = useOrderStateSelector();
  const dispatch = useAppDispatch();
  const formId = useFormIdSelector();
  const confirmationLoaded = useConfirmationLoadedSelector();
  const paymentResult = usePaymentResultStateSelector();
  const isFromPayment = !!paymentResult?.id;

  React.useEffect(() => {
    if (order?.id && formId && !confirmationLoaded) {
      dispatch(getOrderConfirmation({ orderId: order.id, formId }));
    }
  }, [order?.id, formId, confirmationLoaded, dispatch]);

  return isFromPayment ? <PaymentConfirmation /> : <OrderConfirmation />;
};
