import { ExpiredWaitlistOffer } from 'components/ExpiredWaitlistOffer/ExpiredWaitlistOffer';

const ExpiredWaitlistOfferPage = () => {
  return (
    <>
      <ExpiredWaitlistOffer />
    </>
  );
};
export default ExpiredWaitlistOfferPage;
