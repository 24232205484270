import * as React from 'react';
import { Cell, Grid } from '@teamsnap/teamsnap-ui';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { createUser, useLoginEmailSelctor } from 'state/user/userSlice';
import { ActionContainer } from 'components/shared';
import { FormField, FormFieldType } from 'components/shared/FormField/FormField';
import * as validations from '../../core/validations';
import { CreateUser } from 'core/api';

const schema = Yup.object({
  firstName: validations.FIRST_NAME,
  lastName: validations.LAST_NAME,
  email: validations.EMAIL,
  gender: validations.GENDER,
  password: validations.PASSWORD,
  birthdate: validations.BIRTH_DATE,
  termsAndConditions: validations.TERMS_AND_PRIVACY,
  newsletter: validations.NEWSLETTER,
});

export const NewUser = () => {
  const email = useLoginEmailSelctor() || '';
  const dispatch = useAppDispatch();
  const { orderId } = useParams<{ orderId: string }>();

  const navigate = useAppNavigate();

  React.useEffect(() => {
    document.title = 'Signup with TeamSnap!';
  });

  const onSubmit = async (values: CreateUser, { setFieldError, setSubmitting }: FormikHelpers<CreateUser>) => {
    const { data } = await dispatch(createUser(values)).unwrap();

    if (data?.success) {
      navigate(`/order/${orderId}`, { replace: true });
    } else {
      setSubmitting(false);
      if (data?.error === 'email_in_use') {
        setFieldError('password', 'An account already exists for this email');
      } else if (data?.error === 'password_too_easy') {
        setFieldError('password', 'Password is too easy to guess.');
      } else {
        setFieldError(
          'password',
          'Oh no! It looks like something went wrong. Please try again or contact TeamSnap Support for help.'
        );
      }
    }
  };

  return (
    <div className="NewUser">
      <Formik
        initialValues={
          {
            firstName: '',
            lastName: '',
            email,
            password: '',
            gender: '',
            birthdate: '',
            termsAndConditions: false,
            newsletter: false,
          } as CreateUser
        }
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer
            title="Order - create user"
            displayBackButton={true}
            action={handleSubmit}
            actionLabel="Create Account"
          >
            <Form>
              <Grid isWithGutter>
                <Cell>
                  <h1 className="sui-heading-md">Let's create a TeamSnap account!</h1>
                </Cell>

                <Cell>
                  <Grid>
                    <Cell mods="u-size1of2 sui-pr-0.5">
                      <FormField
                        type={FormFieldType.TEXT}
                        label="Your First Name*"
                        name="firstName"
                        placeholder="First name"
                      />
                    </Cell>

                    <Cell mods="u-size1of2 sui-pl-0.5">
                      <FormField
                        type={FormFieldType.TEXT}
                        label="Your Last Name*"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </Cell>
                  </Grid>
                </Cell>

                <Cell>
                  <FormField type={FormFieldType.DATETIME} label="Your Birthdate*" name="birthdate" />
                </Cell>

                <Cell>
                  <FormField
                    type={FormFieldType.SELECT}
                    label="Gender*"
                    name="gender"
                    options={[
                      {
                        label: 'Male',
                        value: 'male',
                      },
                      {
                        label: 'Female',
                        value: 'female',
                      },
                    ]}
                  />
                </Cell>
              </Grid>

              <Cell>
                <FormField
                  type={FormFieldType.PASSWORD_INPUT}
                  label="Password*"
                  name="password"
                  placeholder="8+ characters"
                />
              </Cell>

              <Cell className="u-spaceTopXl">
                <FormField
                  type={FormFieldType.CHECKBOX}
                  label={
                    <p className="sui-body" style={{ lineHeight: 1.5, top: '-5px', position: 'relative' }}>
                      I agree to Teamsnap's{' '}
                      <a href="https://www.teamsnap.com/terms" target="_blank" rel="noreferrer">
                        Terms & Services
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.teamsnap.com/privacy-policy"
                        target="_blank"
                        className="u-textNoWrap"
                        rel="noreferrer"
                      >
                        Privacy Policy.
                      </a>
                    </p>
                  }
                  name="termsAndConditions"
                />
              </Cell>

              <Cell>
                <FormField
                  mods="sui-font-size-5"
                  type={FormFieldType.CHECKBOX}
                  label="Send me occasional TeamSnap news."
                  name="newsletter"
                />
              </Cell>
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
