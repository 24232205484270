import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import { useOrderStateSelector } from 'state/order/orderSlice';
import { Installments } from 'components/Installments/Installments';
import { getInstallmentOptions, useInstallmentPreviewStateSelector } from 'state/installments/installmentsSlice';

const InstallmentsPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useAppDispatch();
  const orderState = useOrderStateSelector();
  const installmentData = useInstallmentPreviewStateSelector();

  React.useEffect(() => {
    if (orderId) {
      dispatch(getInstallmentOptions(+orderId));
    }

  }, [orderState, orderId, dispatch]);

  return (
    <>
      <Installments installmentOptions={installmentData} order={orderState} />
    </>
  );
};
export default InstallmentsPage;
