import { Button, Modal } from '@teamsnap/teamsnap-ui'
import './PaymentExpirationModal.scss'
import { Icon } from '@teamsnap/snap-ui'

export interface PaymentExpirationModalProps {
  isOpen: boolean
  onClose: () => void;
}

export const PaymentExpirationModal = ({ isOpen, onClose }: PaymentExpirationModalProps) => {
  return (
    <div className='PaymentExpirationModal'>
      <Modal
        heading={<div />}
        show={isOpen}
        closeFn={onClose}
      >
        <div className='sui-flex sui-flex-col sui-mx-4 sui-items-center'>
          <Icon
            filled
            name="warning"
            size="xl"
            style={{ color: '#FCD603' }}
          />
          <div className='sui-mt-2 sui-font-size-6'>
            Checkout session has expired.
          </div>
          <div className='sui-mt-6 sui-font-size-5 sui-text-center'>
            Spots in the options have been released.
            You will be directed back to your cart.
          </div>
          <Button
            color="primary"
            onClick={onClose}
            mods="sui-w-full sui-mt-8 sui-mb-2 sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
          >
            Go to Cart
          </Button>
        </div>
      </Modal>
    </div>
  )
}
