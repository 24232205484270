"use strict";
/**
 * @name Icon
 *
 * @description
 *  An icon component used to render inline SVG icons in the html.  See the teamsnap patterns
 *  library for more information https://teamsnap-ui-patterns.netlify.com/patterns/components/icon.html
 *
 *  For a full list of available icons - https://teamsnap-ui-patterns.netlify.app/design-elements/icons.html
 *
 * @example
 *  <Icon name='location' />
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClassName = exports.stringifyArray = void 0;
var React = __importStar(require("react"));
var PropTypes = __importStar(require("prop-types"));
var stringifyArray = function (array, joinBy) {
    if (joinBy === void 0) { joinBy = ' '; }
    return array.filter(Boolean).join(joinBy);
};
exports.stringifyArray = stringifyArray;
var getClassName = function (className) {
    var classModifiers = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        classModifiers[_i - 1] = arguments[_i];
    }
    var classes = __spreadArray([className], classModifiers, true);
    return (0, exports.stringifyArray)(classes);
};
exports.getClassName = getClassName;
var propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    mods: PropTypes.string,
    style: PropTypes.object,
    testId: PropTypes.string,
    otherProps: PropTypes.object,
};
var Icon = function (_a) {
    var name = _a.name, className = _a.className, mods = _a.mods, style = _a.style, testId = _a.testId, otherProps = _a.otherProps;
    var _b = React.useState(null), icon = _b[0], setIcon = _b[1];
    var loadImage = function (iconName) {
        Promise.resolve().then(function () { return __importStar(require("./icons/".concat(iconName))); }).then(function (svg) {
            setIcon(svg.default);
        });
    };
    loadImage(name);
    if (icon) {
        return (React.createElement("svg", __assign({ width: "24", height: "24", className: (0, exports.getClassName)(className, mods), style: style, "data-testid": testId }, icon.metadata, otherProps, { dangerouslySetInnerHTML: { __html: icon.source } })));
    }
    return React.createElement(React.Fragment, null);
};
Icon.defaultProps = {
    className: 'Icon',
    mods: null,
    style: {},
    otherProps: {},
};
exports.default = Icon;
