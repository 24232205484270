import { InstallmentPlanResult, InstallmentResult } from 'core/api';
import { DateFormat, DateService, getFormattedAmount } from 'frontend-toolkit';
import './InstallmentPreview.scss';

const getDateString = (scheduledDate: string | null, i: number, frequencyAmount?: number, frequencyType?: string) => {
  if (i === 0) {
    return 'Today';
  }
  if (frequencyAmount && frequencyType) {
    const count = frequencyAmount * i;
    return `Due in ${count} ${frequencyType === 'monthly' ? 'month' : 'week'}${count === 1 ? '' : 's'}`;
  }
  if (scheduledDate) {
    return DateService.format(new Date(scheduledDate), DateFormat.DATE_WITH_TIME);
  }
  return '';
};

export const InstallmentPreview = ({
  installments,
  installmentPlan,
  showFee,
}: {
  installments: InstallmentResult[];
  installmentPlan?: InstallmentPlanResult;
  showFee?: boolean;
}) => {
  return (
    <ul className="InstallmentPreview__payment-timeline sui-mt-1" data-testid="InstallmentPreview">
      {installments.map((installment, i) => (
        <li key={installment.id} className="InstallmentPreview__payment-line-item">
          <span className="">
            {getFormattedAmount(
              (showFee && installment.feeAmount ? installment.feeAmount + installment.amount : installment.amount) / 100
            )}
          </span>
          <span className="">
            <small>
              {getDateString(
                installment.scheduledDate,
                i,
                installmentPlan?.frequencyAmount,
                installmentPlan?.frequencyType
              )}
            </small>
          </span>
        </li>
      ))}
    </ul>
  );
};
