import * as React from 'react';
import { Button, ScreenReader } from '@teamsnap/teamsnap-ui';

import './ActionContainer.scss';
import { useAppNavigate } from 'state/hooks';

interface Props {
  action?: () => void;
  actionLabel?: string;
  actionGroup?: React.ReactNode[];
  children: JSX.Element;
  contentStyles?: React.CSSProperties;
  displayBackButton?: boolean;
  goBackAction?: () => void;
  footer?: React.ReactNode;
  extraFooter?: React.ReactNode;
  header?: React.ReactNode;
  title?: string;
  submitting?: boolean;
  removeContentFormatting?: boolean;
}

export const ActionContainer = ({
  action,
  actionLabel,
  actionGroup,
  children,
  contentStyles,
  displayBackButton,
  goBackAction,
  footer,
  extraFooter,
  header,
  title,
  submitting = false,
  removeContentFormatting = false,
}: Props) => {
  const navigate = useAppNavigate();

  const handleGoBack = () => {
    if (goBackAction) {
      goBackAction();
    } else {
      navigate(-1);
    }
  };

  return (
    <div data-testid="action-container" className="ActionContainer">
      {!header && title && (
        <header data-testid="action-container-header" className="ActionContainer-header">
          <div className="sui-flex sui-items-center sui-justify-center sui-px-3">
            <div className="sui-flex-1 sui-flex sui-justify-start">
              {displayBackButton && (
                <Button
                  onClick={handleGoBack}
                  size="large"
                  mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                  type="link"
                  icon="arrow-left"
                >
                  <ScreenReader>Go Back</ScreenReader>
                </Button>
              )}
            </div>
            <div
              data-testid="action-container-title"
              className="sui-heading-sm sui-flex sui-items-center sui-justify-center sui-px-1"
            >
              {title}
            </div>
            <div className="sui-flex-1"></div>
          </div>
        </header>
      )}

      {header && header}

      <div data-testid="action-container-body" className="ActionContainer-body" style={contentStyles}>
        <div data-testid="action-container-content" className="ActionContainer-content">
          <div className={`${removeContentFormatting ? '' : 'format'}`}>{children}</div>
        </div>
      </div>

      {extraFooter && extraFooter}

      {action && !footer && (
        <footer data-testid="action-container-footer" className="ActionContainer-footer sui-px-3 sui-py-1">
          <div className="t:sui-flex t:items-center t:sui-justify-between">
            <div>
              {displayBackButton && (
                <Button
                  mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
                  onClick={handleGoBack}
                  icon="arrow-left"
                  iconPosition="left"
                >
                  Back
                </Button>
              )}
            </div>
            <Button
              color="primary"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              onClick={action}
              icon="arrow-right"
              iconPosition="right"
              isDisabled={submitting}
            >
              {actionLabel}
            </Button>
          </div>
        </footer>
      )}

      {!footer && actionGroup && (
        <footer
          data-testid="action-container-footer"
          className="ActionContainer-footer sui-px-3 sui-py-1 sui-px-3 sui-py-1"
        >
          <div className="t:sui-flex t:items-center t:sui-justify-between">{actionGroup.map((action) => action)}</div>
        </footer>
      )}

      {footer && (
        <footer data-testid="action-container-footer" className="ActionContainer-footer sui-px-3 sui-py-1">
          {footer}
        </footer>
      )}
    </div>
  );
};
