"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDatalayer = exports.createOrUpdateDataLayer = exports.getSavedAnalyticsData = exports.trackEventClassic = exports.trackEvent = exports.Event = exports.ScreenName = void 0;
var types_1 = require("./types");
Object.defineProperty(exports, "ScreenName", { enumerable: true, get: function () { return types_1.ScreenName; } });
Object.defineProperty(exports, "Event", { enumerable: true, get: function () { return types_1.Event; } });
/**
 * Add event details to dataLayer
 */
var trackEvent = function (analytics, // instance init in consuming app
logEvent, // firebase cb
passedScreenName, event, params) {
    var _a = passedScreenName.split('/'), featureName = _a[0], subFeatureName = _a[1], screenName = _a[2];
    var formattedSubFeature = subFeatureName === '' ? null : subFeatureName;
    var analyticsInfo = (0, exports.getSavedAnalyticsData)() || {};
    var eventParams = __assign({ division_id: analyticsInfo.DivisionId, event: event, external_referer: analyticsInfo.ExternalReferer, feature: featureName, organization_id: analyticsInfo.OrgId, page_path: window.location.pathname, page: screenName, platform: analyticsInfo.Platform, previous_page_path: 'working on this', product: analyticsInfo.Product, program_season_id: analyticsInfo.ProgramSeasonId, sub_feature: formattedSubFeature, team_id: analyticsInfo.TeamId, team_plan: analyticsInfo.TeamPlan, timestamp: new Date().toISOString(), timezone_offset: formatTimezoneOffset(), url: window.location.href, user_agent_string: analyticsInfo.UserAgent, user_id: analyticsInfo.UserId, user_role: analyticsInfo.UserRole }, params);
    logEvent(analytics, event, eventParams);
};
exports.trackEvent = trackEvent;
/**
 * Create an event using classic's configured firebase instance
 */
var trackEventClassic = function (passedScreenName, event, params) {
    // @ts-ignore
    if (window.teamsnapTrackEvent) {
        // @ts-ignore
        window.teamsnapTrackEvent(passedScreenName, event, params);
    }
    else {
        console.error('trackEventClassic failed due to missing teamsnapTrackEvent function');
    }
};
exports.trackEventClassic = trackEventClassic;
var dataLayerFilter = function (e) { return e['Type'] === 'AnalyticsInfo'; };
/**
 * Pulls out saved info from dataLayer that classic will set
 */
var getSavedAnalyticsData = function () { var _a; 
// @ts-ignore
return (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.find(dataLayerFilter); };
exports.getSavedAnalyticsData = getSavedAnalyticsData;
/**
 * for apps not loaded by classic, create or replace the index with AnalyticsInfo
 */
var createOrUpdateDataLayer = function (data) {
    var _a, _b, _c;
    var analyticsInfo = (0, exports.getSavedAnalyticsData)();
    if (!analyticsInfo) {
        // @ts-ignore
        (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push(__assign(__assign({}, data), { 'Type': 'AnalyticsInfo' }));
    }
    else {
        // @ts-ignore
        var dataLayerIndex = (_b = window.dataLayer) === null || _b === void 0 ? void 0 : _b.findIndex(dataLayerFilter);
        // @ts-ignore
        (_c = window.dataLayer) === null || _c === void 0 ? void 0 : _c.splice(dataLayerIndex, 1, __assign(__assign({}, analyticsInfo), data));
    }
};
exports.createOrUpdateDataLayer = createOrUpdateDataLayer;
/**
 * When navigating within SPA apps we may need to update items
 */
var updateDatalayer = function (key, value) {
    var _a;
    var _b, _c;
    var analyticsInfo = (0, exports.getSavedAnalyticsData)();
    if (analyticsInfo) {
        // @ts-ignore
        var dataLayerIndex = (_b = window.dataLayer) === null || _b === void 0 ? void 0 : _b.findIndex(dataLayerFilter);
        // @ts-ignore
        (_c = window.dataLayer) === null || _c === void 0 ? void 0 : _c.splice(dataLayerIndex, 1, __assign(__assign({}, analyticsInfo), (_a = {}, _a[key] = value, _a)));
    }
};
exports.updateDatalayer = updateDatalayer;
/**
 * Formats timezone offset to be hour and minute difference from GMT
 * i.e. '-500' for Eastern Standard Time or '+530' for India Standard Time
 */
var formatTimezoneOffset = function () {
    var tzOffset = new Date().getTimezoneOffset();
    var tzDate = new Date(0, 0, 0, 0, Math.abs(tzOffset));
    return "".concat(tzOffset > 0 ? '-' : '+').concat(tzDate.getHours()).concat(('00' + tzDate.getMinutes()).slice(-2));
};
