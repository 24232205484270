/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateUserResult = {
    success: boolean;
    token?: string;
    error?: CreateUserResult.error;
};

export namespace CreateUserResult {

    export enum error {
        EMAIL_IN_USE = 'email_in_use',
        PASSWORD_TOO_EASY = 'password_too_easy',
        UNKNOWN = 'unknown',
    }


}

