import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';
import { Icon } from '@teamsnap/snap-ui';

import './RejectWaitlistOffer.scss';

type Props = {
  organizationName: string;
};

// eslint-disable-next-line react/prop-types
export const RejectWaitlistOffer: React.FC<Props> = ({ organizationName }) => {
  return (
    <div className="RejectWaitlistOffer">
      <ActionContainer
        removeContentFormatting={true}
        submitting={false}
        contentStyles={{ backgroundColor: 'white' }}
        header={<Header noProfile title={organizationName} />}
      >
        <div className="sui-bg-white sui-py-2 sui-px-3 sui-mt-2">
          <div className="sui-flex sui-text-green-40">
            <span className="sui-mt-1">
              <span className="d:sui-hidden">
                <Icon name="check_circle" />
              </span>
              <span className="sui-hidden d:sui-inline-block">
                <Icon name="check_circle" size="l" />
              </span>
            </span>
            <span className="sui-heading-md sui-pl-1 sui-font-normal">
              Your decision has been sent to {organizationName}
            </span>
          </div>
          <p className="sui-body sui-text-gray-40 sui-mt-2 sui-text-center">Thank you for your response.</p>
        </div>
      </ActionContainer>
    </div>
  );
};
