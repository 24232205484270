/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateMakePayment, CreatePrepareNextPayment, OrdersService, UpdatePaymentMethod } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledSubReducer, asyncPendingReducer, handleErrors, useAppSelector } from '../hooks';
import { PaymentState } from './types';

const initialState: AsyncState<PaymentState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const createNextPayment = createAsyncThunk(
  'payment/create',
  (prepareNextPaymentParams: CreatePrepareNextPayment) =>
    handleErrors(async () => await OrdersService.createNextPayment(prepareNextPaymentParams))
);

export const createOfflinePayment = createAsyncThunk('payment/offlinePayment', (params: { orderId: number }) =>
  handleErrors(async () => OrdersService.offlineCheckout(params.orderId))
);

export const makePayment = createAsyncThunk('result/create', (makePaymentParams: CreateMakePayment) =>
  handleErrors(async () => OrdersService.createPayment(makePaymentParams))
);

export const updatePaymentMethod = createAsyncThunk('payment/updateMethod', (params: UpdatePaymentMethod) =>
  handleErrors(async () => OrdersService.updatePaymentMethod(params))
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNextPayment.pending, asyncPendingReducer);
    builder.addCase(createNextPayment.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'payment'));

    builder.addCase(makePayment.pending, asyncPendingReducer);
    builder.addCase(makePayment.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'result'));

    builder.addCase(updatePaymentMethod.pending, asyncPendingReducer);
    builder.addCase(updatePaymentMethod.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'method')
    );

    builder.addCase(createOfflinePayment.pending, asyncPendingReducer);
    builder.addCase(createOfflinePayment.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'offlinePaymentResult'));
  },
});

export const usePaymentStateSelector = () => useAppSelector((state) => state.payment?.data?.payment);
export const usePaymentResultStateSelector = () => useAppSelector((state) => state.payment?.data?.result);
export const usePaymentMethodStateSelector = () => useAppSelector((state) => state.payment?.data?.method);
export const useOfflinePaymentResultSelector = () => useAppSelector((state) => state.payment?.data?.offlinePaymentResult);
export const usePaymentStateErrorSelector = () => useAppSelector((state) => state.payment?.errors);

// export const usePaymentStateErrorSelector = () => useAppSelector((state) => state.payment.error);
// export const usePaymentStateErrorsSelector = () => useAppSelector((state) => state.payment.errors);

export default paymentSlice.reducer;
