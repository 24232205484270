/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeclineOfferInterface } from '../models/DeclineOfferInterface';
import type { FormResultInterface } from '../models/FormResultInterface';
import type { FormResultsResponse } from '../models/FormResultsResponse';
import type { WaitlistAcceptOfferInterface } from '../models/WaitlistAcceptOfferInterface';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormResultsService {

    /**
     * Loads form results by form result id
     * @param formResultIds
     * @returns FormResultsResponse Success
     * @throws ApiError
     */
    public static getFormResults(
        formResultIds: string,
    ): CancelablePromise<FormResultsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/form-results',
            query: {
                'formResultIds': formResultIds,
            },
        });
    }

    /**
     * Accept the waitlist offer and return the order
     * @param offerToken
     * @returns WaitlistAcceptOfferInterface Success
     * @throws ApiError
     */
    public static waitlistAcceptOffer(
        offerToken: string,
    ): CancelablePromise<WaitlistAcceptOfferInterface> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/form-results/{offerToken}/waitlist-accept-offer',
            path: {
                'offerToken': offerToken,
            },
        });
    }

    /**
     * Returns the form result by the offer token
     * @param offerToken
     * @returns FormResultInterface Success
     * @throws ApiError
     */
    public static getFormResultByOfferToken(
        offerToken: string,
    ): CancelablePromise<FormResultInterface> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/form-results/{offerToken}',
            path: {
                'offerToken': offerToken,
            },
        });
    }

    /**
     * Declines a waitlist offer.
     * @param id
     * @returns DeclineOfferInterface Success
     * @throws ApiError
     */
    public static declineWaitlistOffer(
        id: number,
    ): CancelablePromise<DeclineOfferInterface> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/form-results/{id}/decline-offer',
            path: {
                'id': id,
            },
        });
    }

}
