"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = exports.ScreenName = void 0;
/**
 * Screen name options
 *
 * Each screen name translates to a feature/sub feature/screen name string. They
 * are all included in one enum to ensure screen names are unique.
 *
 * Format:
 * screenName = 'feature/sub_feature/Screen Name'
 *
 * Note that these are alphabetized by feature then sub-feature (with null
 * coming last) then screen name. Since they are all in one enum this
 * alphabetizing helps find them faster.
 */
var ScreenName;
(function (ScreenName) {
    // ============================================
    // FEATURE: invoicing
    // ----- Sub-feature: payout_setup
    ScreenName["financialSettingsAddBank"] = "invoicing/payout_setup/Financial Settings Add Bank";
    ScreenName["financialSettingsStripe"] = "invoicing/payout_setup/Financial Settings Stripe";
    ScreenName["invoicing"] = "invoicing/payout_setup/Invoicing";
    // ============================================
    // FEATURE: login
    // ----- Sub-feature: null
    ScreenName["login"] = "login//Login";
    // ============================================
    // FEATURE: onboarding
    // ----- Sub-feature: account_creation_flow
    ScreenName["chooseRole"] = "onboarding/account_creation_flow/Choose Role";
    ScreenName["createAccount"] = "onboarding/account_creation_flow/Create Account";
    ScreenName["createTeam"] = "onboarding/account_creation_flow/Create Team";
    ScreenName["enterAccountEmail"] = "onboarding/account_creation_flow/Enter Account Email";
    // ----- Sub-feature: team_creation_flow
    ScreenName["addRoster"] = "onboarding/team_creation_flow/Add Roster";
    ScreenName["addSchedule"] = "onboarding/team_creation_flow/Add Schedule";
    ScreenName["rosterHelpModal"] = "onboarding/team_creation_flow/Roster Help Modal";
    ScreenName["teamSettings"] = "onboarding/team_creation_flow/Team Settings";
    ScreenName["teamSetupComplete"] = "onboarding/team_creation_flow/Team Setup Complete";
    // ============================================
    // FEATURE: roster
    // ----- Sub-feature: null
    ScreenName["addRosterFromFile"] = "roster//Add Roster from File";
    ScreenName["addRosterFromTeam"] = "roster//Add Roster from Team";
    ScreenName["addRosterMemberManually"] = "roster//Add Roster Manually";
    ScreenName["memberProfile"] = "roster//Member Profile";
    ScreenName["rosterList"] = "roster//Roster List";
    // ============================================
    // FEATURE: schedule
    // ----- Sub-feature: assignments
    ScreenName["assignments"] = "schedule/assignments/Assignments";
    ScreenName["createNewAssignment"] = "schedule/assignments/Create New Assignment";
    // ----- Sub-feature: availability
    ScreenName["availability"] = "schedule/availability/Availability";
    ScreenName["availabilityAttendanceForRoster"] = "schedule/availability/Availability Attendance for Roster";
    // ----- Sub-feature: detail
    ScreenName["addEvent"] = "schedule/detail/Add Event";
    ScreenName["addLocation"] = "schedule/detail/Add Location";
    ScreenName["createGame"] = "schedule/detail/Create Game";
    ScreenName["eventDetails"] = "schedule/detail/Event Details";
    ScreenName["locationDetails"] = "schedule/detail/Location Details";
    // ----- Sub-feature: null
    ScreenName["scheduleList"] = "schedule//Schedule List";
    ScreenName["sponsorships"] = "v2_discovery/promo_sponsorship/Sponsorship";
    ScreenName["websites"] = "v2_discovery/promo_websites/Websites";
    ScreenName["tournaments"] = "v2_discovery/promo_tournaments/Tournament";
    // Reg 2.0
    ScreenName["registrationSetupWizard"] = "registration/registration_setup_wizard/Registration Set-Up Wizard";
})(ScreenName = exports.ScreenName || (exports.ScreenName = {}));
// Event types
var Event;
(function (Event) {
    Event["viewPage"] = "page_view";
    Event["click"] = "click";
    Event["loginComplete"] = "login_complete";
    Event["registrationFormWizardComplete"] = "registration_form_wizard_completed";
    Event["registrantCreated"] = "registrant_created";
})(Event = exports.Event || (exports.Event = {}));
