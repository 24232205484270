import { useEffect, useState } from 'react';

const useCountdown = (targetTime: number) => {
  const [start] = useState(new Date().getTime());
  const [millisLeft, setMillisLeft] = useState(newMillisLeft(targetTime));

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const updateCountdown = () => {
      if (new Date().getTime() < start) {
        // This should never happen unless the user moved their clock back
        setMillisLeft(0);
      } else {
        setMillisLeft(newMillisLeft(targetTime));
      }
    };

    if (millisLeft > 0) {
      interval = setInterval(updateCountdown, 1000);
    }

    return () => clearInterval(interval);
  }, [targetTime, millisLeft, start]);

  return getReturnValues(millisLeft);
};

function newMillisLeft(targetTime: number) {
  return Math.max(0, targetTime - new Date().getTime());
}

const getReturnValues = (millisLeft: number) => {
  const minutes = Math.floor(millisLeft / 60000);
  const seconds = Math.floor((millisLeft % 60000) / 1000);

  return [minutes, seconds];
};

export { useCountdown };
