import { useInstallmentStateSelector } from 'state/installments/installmentsSlice';
import { useOrderStateSelector } from 'state/order/orderSlice';

import { DateFormat, DateService, getFormattedAmount } from 'frontend-toolkit';
import { PaymentMethodResult } from 'core/api';
import { capitalize } from 'components/Installments/utils';

export const formatMethod = (paymentMethod?: Partial<PaymentMethodResult> | null) => {
  if (paymentMethod?.type === 'card' || paymentMethod?.type === 'us_bank_account') {
    return `${capitalize(paymentMethod?.accountName || '')} (**** ${paymentMethod?.last4})`;
  }

  // TODO handle other types

  return '';
};

export const useOrderHelpers = () => {
  const order = useOrderStateSelector();
  const installments = useInstallmentStateSelector();
  const latestPaymentMethod = installments?.filter((i) => !!i.paymentMethod)?.slice(-1)[0]?.paymentMethod;

  const totalProcessingFees = installments?.reduce((acc, i) => {
    acc = i.feeAmount ? acc + i.feeAmount : acc;
    return acc;
  }, 0);

  // will only show discounts line item in total summary for old discounts, new ones will be shown at line item level
  const totalDiscounts = order?.orderLineItems?.reduce((acc, li) => {
    if (
      // this only includes old discounts (at cart level), new ones have scopeType as form_result
      li.type === 'credit' &&
      li.scopeType === 'form' &&
      (li.externalType === 'automatic_discount' || li.externalType === 'coupon_code_discount')
    ) {
      return acc + li.amount;
    }
    return acc;
  }, 0);

  const paymentMethodText = () => {
    if (latestPaymentMethod) {
      return formatMethod(latestPaymentMethod);
    }

    if (order?.allowOfflineCheckout && order.status === 'waiting_for_payment') {
      return 'Waiting for Cash';
    }

    return '';
  };

  return {
    subtotalText:
      order && (order.amount || order.amount === 0)
        ? getFormattedAmount((order.amount - (totalDiscounts ? totalDiscounts : 0)) / 100)
        : '',
    totalProcessingFeesText: totalProcessingFees ? getFormattedAmount(totalProcessingFees / 100) : '',
    totalDiscountsText: totalDiscounts ? getFormattedAmount(totalDiscounts / 100) : '',
    balanceText: order ? getFormattedAmount(order.amountRemaining / 100) : '',
    orderDateText: order ? DateService.dateToString(new Date(order.insertedAt), DateFormat.SHORT_DATE) : '',
    latestPaymentMethodText: paymentMethodText(),
    totalText:
      order && (order.amount || order.amount === 0)
        ? getFormattedAmount((order.amount + (totalProcessingFees ? totalProcessingFees : 0)) / 100)
        : '',
  };
};
