/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistrationConfirmationResponseInterface } from '../models/RegistrationConfirmationResponseInterface';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegistrationConfirmationService {

    /**
     * Preview the form results, confirmation message and order given Order ID and Form ID
     * @param id
     * @param formId
     * @returns RegistrationConfirmationResponseInterface Success
     * @throws ApiError
     */
    public static registrationConfirmation(
        id: number,
        formId: number,
    ): CancelablePromise<RegistrationConfirmationResponseInterface> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{id}/registration-confirmation/{formId}',
            path: {
                'id': id,
                'formId': formId,
            },
        });
    }

}
