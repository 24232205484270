import React from 'react';
import { useOrderStateSelector } from 'state/order/orderSlice';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeElementsOptionsMode } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { STRIPE_CLIENT_KEY } from 'core/constants';
import { createNextPayment, usePaymentStateSelector } from 'state/payment/paymentSlice';
import { UpdatePaymentMethod } from 'components/Payment/UpdatePaymentMethod';
import { useAppDispatch, useAppNavigate } from 'state/hooks';


const stripePromise = loadStripe(STRIPE_CLIENT_KEY, {
  betas: ['elements_enable_deferred_intent_beta_1'],
});

const PaidNotice = () => <div>Order is fully paid</div>; // TODO this is just to prevent errors, may need design input

const UpdatePaymentPage = () => {
  const orderData = useOrderStateSelector();
  const paymentData = usePaymentStateSelector();

  const isPaid = orderData?.status === 'fully_paid';
  const orderId = orderData?.id;
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  React.useEffect(() => {
    if (orderId && !isPaid) {
        dispatch(
          createNextPayment({ orderId: +orderId, paymentOption: 'next_installment' })
        );
      }
    }, [orderId, isPaid, dispatch, navigate]);

  const options : StripeElementsOptionsMode = {
    mode: 'payment',
    amount: paymentData?.amount || 1,
    currency: paymentData?.currency || 'usd',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    payment_method_options: {
      us_bank_account: {
        verification_method: 'instant_or_skip',
        financial_connections: { permissions: ['payment_method'] },
      },
    },
  };

  if (paymentData?.processorExternalAccountId && paymentData?.processorAccountIsMerchant) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options.onBehalfOf = paymentData?.processorExternalAccountId;
  }

  if (isPaid) {
    return <PaidNotice />;
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      <UpdatePaymentMethod orderId={orderData?.id} />
    </Elements>
  );
};

export default UpdatePaymentPage;
