/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Household } from '../models/Household';
import type { HouseholdPerson } from '../models/HouseholdPerson';
import type { HouseholdsAndPersons } from '../models/HouseholdsAndPersons';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HouseholdsService {

    /**
     * Returns households and household people for the current user
     * @returns HouseholdsAndPersons Success
     * @throws ApiError
     */
    public static getHouseholdsAndPersons(): CancelablePromise<HouseholdsAndPersons> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/households-and-persons',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * Returns households for the current user
     * @returns Household Success
     * @throws ApiError
     */
    public static getHouseholds(): CancelablePromise<Array<Household>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/households',
        });
    }

    /**
     * Gets all members for the specified household
     * @param householdId
     * @returns HouseholdPerson Success
     * @throws ApiError
     */
    public static getHouseholdPeople(
        householdId: number,
    ): CancelablePromise<Array<HouseholdPerson>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/households/{householdId}/household-persons',
            path: {
                'householdId': householdId,
            },
        });
    }

}
