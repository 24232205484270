import * as React from 'react';
import { OrderLineItem } from 'core/api';

// Local Imports
import { useOrderStateSelector } from 'state/order/orderSlice';
import { getFormattedAmount } from 'frontend-toolkit';

type OrderSummaryProps = {
  hideSubtotal?: boolean;
};

const OrderSummary: React.FC<OrderSummaryProps> = ({ hideSubtotal }) => {
  const order = useOrderStateSelector();

  const displayLineItem = (lineItem: OrderLineItem, index: number) => (
    <div
      key={lineItem.id}
      className={
        `OrderSummary--item--${index} sui-flex sui-justify-between` +
        (lineItem.type === 'registration_fee' ? '' : ' sui-ml-3')
      }
    >
      <div>
        <p className={`OrderSummary--name-${index} sui-font-size-5`}>{lineItem.name}</p>
      </div>

      <div>
        <p className={`OrderSummary--amount-${index} sui-font-size-5`}>{getFormattedAmount(lineItem.amount / 100)}</p>
      </div>
    </div>
  );

  return (
    <div className="OrderSummary sui-my-2">
      <div className="sui-flex">
        <p className="OrderSummary--title sui-font-size-6 sui-mb-1">
          Order Summary<span className="sui-text-red">*</span>
        </p>
      </div>

      <div className="OrderSummary--items sui-bg-white sui-p-3 sui-rounded">
        {order?.orderLineItems?.map((lineItem, index) => displayLineItem(lineItem, index))}

        {!hideSubtotal && (
          <div className="sui-flex sui-justify-between sui-mt-2 sui-font-size-5 u-borderTop u-borderNeutral5 sui-border-2">
            Subtotal:{' '}
            <span className="OrderSummary--total sui-font-weight-bold sui-ml-1">
              {getFormattedAmount((order?.amount ?? 0) / 100)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
