import { useLocation } from 'react-router-dom';

// Local Imports
import { RejectWaitlistOffer } from 'components/RejectWaitlistOffer/RejectWaitlistOffer';
import ExpiredWaitlistOfferPage from 'pages/ExpiredWaitlistOfferPage/ExpiredWaitlistOfferPage';

const RejectWaitlistOfferPage = () => {
  const location = useLocation();
  const locationState = location.state as { orgName: string };

  if (!locationState?.orgName) {
    return <ExpiredWaitlistOfferPage />;
  }

  return (
    <>
      <RejectWaitlistOffer organizationName={locationState?.orgName} />
    </>
  );
};
export default RejectWaitlistOfferPage;
