/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SignatureType {
    CHECKBOX_ACCEPT = 'checkbox_accept',
    INITIALS = 'initials',
    FULL_NAME = 'full_name',
    E_SIGNATURE = 'e-signature',
}
