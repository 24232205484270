"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuidedSupportService = void 0;
var GuidedSupportService = /** @class */ (function () {
    function GuidedSupportService() {
    }
    /**
     * Adds user information to the walkme global.
     * Defaults to being additive, however, if replace is provided (and is true),
     * it will replace the existing config with the new provided config.
     * @param product String product for walkme, ex: "classic", "org", "360"
     * @param userId The logged in user ID
     * @param replace
     */
    GuidedSupportService.registerUserInformation = function (userData, replace) {
        if (replace === void 0) { replace = false; }
        var existingData = window.walkMeUsers || {};
        window.walkMeUsers = Object.assign(replace ? {} : existingData, userData);
    };
    return GuidedSupportService;
}());
exports.GuidedSupportService = GuidedSupportService;
