import * as React from 'react';
import './Banner.scss';

interface Props {
  children: React.ReactNode;
  classes?: string;
  rounded?: boolean;
  warning?: boolean;
}

export const Banner = ({
  children,
  classes,
  rounded,
  warning
}: Props) => {
  const allClasses = [
    rounded ? 'u-borderRadiusMd' : '',
    warning ? 'warning-banner' : '',
    classes ? classes : '',
  ]
    .join(' ')
    .replace(/\s+/g, ' ');

  return (
    <div data-testid="banner-area">
      <div
        className={allClasses}
        data-testid="banner-area-body"
      >
        {children}
      </div>
    </div>
  )
}
