import React from 'react';
import { DateFormat, DateService } from 'frontend-toolkit';
import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared';
import { ACCOUNTS_URL } from 'core/constants';
import { Icon } from '@teamsnap/teamsnap-ui';
import { useAppDispatch } from 'state/hooks';
import { LabelButton, Checkbox, ModalContainer, Input, Icon as SnapUIIcon } from '@teamsnap/snap-ui';
import { FormResultInterface } from 'core/api';
import {
  getOrderConfirmation,
  useFormIdSelector,
  useFormResultsSelector,
  useOrderStateSelector,
} from 'state/order/orderSlice';
import { groupBy } from 'core/utils';

import './AgreementsPage.scss';

type WaiverResult = FormResultInterface['formWaiverResults'][0];

const isImage = (waiverFileName: string) =>
  waiverFileName.includes('.png') ||
  waiverFileName.includes('.jpg') ||
  waiverFileName.includes('.jpeg') ||
  // This is in place due to bug where file extension was not used on upload
  waiverFileName.includes('image/png') ||
  waiverFileName.includes('image/jpg') ||
  waiverFileName.includes('image/jpeg');

const getFileType = (waiverFileName: string) => {
  if (
    waiverFileName.includes('.png') ||
    waiverFileName.includes('.jpg') ||
    waiverFileName.includes('.jpeg') ||
    waiverFileName.includes('image/png') ||
    waiverFileName.includes('image/jpg') ||
    waiverFileName.includes('image/jpeg')
  ) {
    return 'image';
  } else if (waiverFileName.includes('.pdf') || waiverFileName.includes('application/pdf')) {
    return 'pdf';
  } else {
    return 'doc';
  }
};

const getIconName = (waiverFileName: string) => {
  if (waiverFileName.includes('.pdf') || waiverFileName.includes('application/pdf')) {
    return 'picture_as_pdf';
  } else {
    return 'description';
  }
};

const WaiverFile = ({ originalFileName, downloadUrl }: { originalFileName: string; downloadUrl: string }) => {
  console.log('downloadUrl', downloadUrl);
  return (
    <>
      {isImage(originalFileName) ? (
        <>
          {downloadUrl ? (
            <a
              rel="noreferrer"
              data-testid={`WaiverFile--download-${getFileType(originalFileName)}-link`}
              className="WaiverFile--download-link"
              target="_blank"
              href={downloadUrl}
              download={originalFileName}
            >
              adsfasdf
              <img
                data-testid="WaiverFile--image"
                className="WaiverFile--image sui-w-full sui-p-2 mx-auto"
                alt={originalFileName}
                src={downloadUrl}
              />
            </a>
          ) : (
            <div>
              <figure className="sui-flex sui-flex-col sui-items-center">
                <img
                  data-testid="WaiverFile--image"
                  className="WaiverFile--image--error WaiverFile--image mx-auto"
                  alt={originalFileName}
                  src={downloadUrl}
                />
                <figcaption className="sui-body sui-text-neutral-text-medium sui-mt-1 sui-text-center">
                  {originalFileName}
                </figcaption>
              </figure>
              <p className="sui-mb-4 sui-caption sui-text-negative-text sui-text-center sui-mt-2 sui-flex sui-items-center sui-justify-center">
               <SnapUIIcon name="report" size="s" /> Failed to retrieve image. Refresh the page.
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {downloadUrl ? (
            <>
              <a
                rel="noreferrer"
                data-testid={`WaiverFile--download-${getFileType(originalFileName)}-link`}
                className="WaiverFile--download-link sui-inline-flex sui-items-center sui-mb-1 sui-text-action-text"
                target="_blank"
                href={downloadUrl}
              >
                <SnapUIIcon name={getIconName(originalFileName)} className="sui-mr-1" />
                {originalFileName ? originalFileName : `Download ${getFileType(originalFileName)}`}
              </a>
              <p className="sui-body sui-mb-4 sui-caption">Click to view {getFileType(originalFileName)} file</p>
            </>
          ) : (
            <>
              <div
                data-testid={`WaiverFile--download-${getFileType(originalFileName)}-link`}
                className="sui-inline-flex sui-items-center sui-mb-1 sui-text-neutral-text-disabled"
              >
                <SnapUIIcon name={getIconName(originalFileName)} className="sui-mr-1" />
                {originalFileName ? originalFileName : `Download ${getFileType(originalFileName)}`}
              </div>
              <p className="sui-body sui-mb-4 sui-caption sui-text-negative-text">
                Failed to retrieve file. Refresh page to try again.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

const AgreementsPage = () => {
  const formResults = useFormResultsSelector();
  const formId = useFormIdSelector();
  const order = useOrderStateSelector();
  const dispatch = useAppDispatch();
  const [showAgreementModal, setShowAgreementModal] = React.useState(false);
  const [openAgreement, setOpenAgreement] = React.useState<WaiverResult>();

  React.useEffect(() => {
    if (order?.id && formId) {
      dispatch(getOrderConfirmation({ orderId: order.id, formId }));
    }
  }, [order?.id, formId, dispatch]);

  const handleBackButton = () => {
    window.location.href = `${ACCOUNTS_URL}/orders`;
  };

  const onOpenAgreement = (waiverResult: WaiverResult) => {
    setOpenAgreement(waiverResult);
    setShowAgreementModal(true);
  };

  const groupedWaivers = groupBy('formWaiverId', formResults?.flatMap((waiver) => waiver.formWaiverResults) || []);
  const agreements = Object.values(groupedWaivers).flatMap((x) => x[0]);

  return (
    <ActionContainer
      removeContentFormatting
      submitting={false}
      contentStyles={{ backgroundColor: 'white' }}
      header={<Header title="Signed Digital Agreements" profileName={''} />}
    >
      <div className="sui-p-2">
        <ModalContainer size="default" isOpen={showAgreementModal} disableOverlayClose closeButton={false}>
          <div id="digital-agreement__container" className="w-full sui-flex sui-flex-col">
            {openAgreement && (
              <div className="sui-flex-1 sui-flex sui-flex-col sui-pb-2">
                <h3 data-testid="AgreementsPage__waiver-title" className="sui-heading-md sui-mb-4 sui-text-center">
                  {openAgreement.formWaiverTitle}
                </h3>
                <div className="waiver-content">
                  <div
                    data-testid="AgreementsPage__waiver-content"
                    className="sui-mb-4 flex-1"
                    dangerouslySetInnerHTML={{ __html: openAgreement.content }}
                  ></div>
                  {openAgreement.formWaiverFile &&
                    (openAgreement.formWaiverFile.downloadUrl || openAgreement.formWaiverFile.originalFileName) && (
                      <WaiverFile
                        downloadUrl={openAgreement.formWaiverFile.downloadUrl ?? ''}
                        originalFileName={openAgreement.formWaiverFile.originalFileName ?? ''}
                      />
                    )}
                </div>
                {openAgreement.signatureType === 'checkbox_accept' ? (
                  <>
                    <Checkbox
                      label="I agree."
                      name="Checkbox-group"
                      extraProps={{
                        input: {
                          checked: true,
                          disabled: true,
                        },
                      }}
                    />
                  </>
                ) : (
                  <div className="sui-mb-4">
                    <Input
                      label={`Type registrant's ${
                        openAgreement.signatureType === 'initials' ? 'initials' : 'signature'
                      }`}
                      name="signature"
                      size="default"
                      type="text"
                      required
                      value={openAgreement.signature ?? ''}
                      disabled={true}
                      helpText={`If you are registering a child, type in your own ${
                        openAgreement.signatureType === 'initials' ? 'initials' : 'signature'
                      }.`}
                    />
                  </div>
                )}
                <span className="sui-caption sui-text-neutral-text-medium">
                  {openAgreement.signatureType === 'checkbox_accept' ? 'Accepted' : 'Signed'} on:{' '}
                  {DateService.format(new Date(openAgreement.insertedAt), DateFormat.DATE_WITH_TIME)}
                </span>
              </div>
            )}
            <div className="sui-py-4">
              <LabelButton
                data-testid="AgreementsPage__close-button"
                className="sui-w-full"
                variantType="tertiary"
                onClick={() => setShowAgreementModal(false)}
              >
                Close
              </LabelButton>
            </div>
          </div>
        </ModalContainer>

        <LabelButton
          icon="arrow_back"
          iconPosition="left"
          onClick={handleBackButton}
          labelText="Back to Orders"
          variantType="tertiary"
          size="small"
        />

        {agreements.map((agreement, index) => (
          <div
            key={`${agreement.formWaiverTitle}--${index}`}
            className="u-flex u-padLg u-border u-borderRadiusLg u-borderColorNeutral sui-mt-2"
          >
            <div
              data-testid={`agreement-card--${index}`}
              className="sui-flex sui-justify-between u-fontSizeLg u-colorNeutral9 sui-w-full"
            >
              <div className="u-flex u-flexJustifyBetween u-fontSizeLg u-colorNeutral9 sui-items-center">
                <Icon
                  name="task-alt"
                  style={{
                    top: 0,
                    fontSize: '15px',
                    color: '#199a50',
                    marginRight: '5px',
                  }}
                />
                <div data-testid={`agreement-title--${index}`}>{agreement.formWaiverTitle}</div>
              </div>
              <LabelButton variantType="tertiary" onClick={() => onOpenAgreement(agreement)}>
                View
              </LabelButton>
            </div>
          </div>
        ))}
      </div>
    </ActionContainer>
  );
};

export default AgreementsPage;
