
import { FormPartnerOffering } from 'core/api';
import { FormFieldResult, InsuranceOfferState } from 'core/types';
import { US_STATES } from 'core/utils';
import InsuranceAddonDisplay from './InsuranceAddonDisplay';

export type AddonDisplayComponentProps = {
  offerStateChange: (state: InsuranceOfferState[]) => void;
  emptyOfferError: (e: unknown) => void;
  formPartnerOffering: FormPartnerOffering
}

const AddonDisplayComponents: { [k: string]: (props: AddonDisplayComponentProps) => JSX.Element } = {
  'registration-cancellation': InsuranceAddonDisplay,
  'gap-medical': InsuranceAddonDisplay
}

export const getRequiredAddonParticipantFields = (formFieldResult: FormFieldResult) => {
  const participantFormResults = formFieldResult.fields.filter(i => i.target === 'participant');
  const streetAddress = participantFormResults.find(i => i.name === 'street_address')?.value;
  const firstName = participantFormResults.find(i => i.name === 'first_name')?.value;
  const lastName = participantFormResults.find(i => i.name === 'last_name')?.value;
  const zip = participantFormResults.find(i => i.name === 'zip')?.value;
  const birthDate = participantFormResults.find(i => i.name === 'birthdate')?.value;
  const city = participantFormResults.find(i => i.name === 'city')?.value;

  const stateName = participantFormResults?.find(i => i.name === 'state')?.value;
  const stateCode = US_STATES.find(i => i.name === stateName)?.abbreviation;
  return { streetAddress, city, firstName, lastName, zip, birthDate, stateCode };
}

export const findFormFieldResult = (
  formResultId: number,
  formFieldResults: FormFieldResult[]) => {
  return formFieldResults.find(i => i.formResultId === formResultId);
}

export const AddonDisplay = ({
  formPartnerOffering,
  offerStateChange,
  emptyOfferError
}: {
  formPartnerOffering: FormPartnerOffering;
  offerStateChange: (state: InsuranceOfferState[]) => void;
  emptyOfferError: (e: unknown) => void;
}) => {
  if (formPartnerOffering.offering.productCode in AddonDisplayComponents) {
    const Addon = AddonDisplayComponents[formPartnerOffering.offering.productCode];
    return <Addon offerStateChange={offerStateChange} formPartnerOffering={formPartnerOffering} emptyOfferError={emptyOfferError} />;
  } else {
    return null;
  }
}
