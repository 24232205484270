/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getFormattedAmount } from 'frontend-toolkit';
import { InstallmentPlanResult } from 'core/api';

export function capitalize(value: string) {
  if (!value) return value;

  return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export const FREQUENCY_MAP: Record<string, string> = {
  weekly_1: 'Weekly',
  weekly_2: 'Every 2 weeks',
  monthly_1: 'Monthly',
  monthly_2: 'Monthly',
};

export const isMonthly = (value: string) => value?.includes('monthly');

export const getFrequencyOptions = () =>
  Object.entries(FREQUENCY_MAP).map(([value, text]) => ({ value, text })) as { text: string; value: string }[];

export const formatDayOfMonth = (day: string): string => {
  if (['13', '11'].includes(day)) {
    return `${day}th`;
  }
  switch (day.split('').pop()) {
    case '1':
      return `${day}st`;
    case '3':
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatToDollarAmount = (amount?: number | null) => (amount ? getFormattedAmount(amount / 100) : amount);
export const formatToPercentageAmount = (amount?: number | null) => (amount ? (amount / 100).toString() : amount);

export const getInstallmentsString = (installments: number) => `${installments} equal payments`;
export const getFrequencyString = (frequency: string) => (FREQUENCY_MAP[frequency] || '').toLowerCase();
export const joinFrequencyValues = (frequencyType: string, frequencyAmount: number) => {
  const freqKey = `${frequencyType}_${frequencyAmount || 1}`;
  return FREQUENCY_MAP[freqKey] ? freqKey : undefined;
};
export const getWithdrawString = (frequency: string, withdrawDay: string | null) => {
  if (!withdrawDay) return '';
  if (isMonthly(frequency)) {
    return `on the ${formatDayOfMonth(withdrawDay)}`;
  }
  return `on ${capitalize(withdrawDay)}`;
};

// build the natural language description of the plan from the plan object
export const getDepositDescription = ({ depositType, depositAmount }: Partial<InstallmentPlanResult>) => {
  switch (depositType) {
    case 'amount':
      return `Deposit of ${formatToDollarAmount(depositAmount)}, then`;
    case 'percentage':
      return `Deposit of ${formatToPercentageAmount(depositAmount)}%, then`;
    case 'first_installment':
      return '';
    default:
      return '';
  }
};

export const getInstallmentsDescription = ({
  depositType,
  installmentCount = 1,
  frequencyType = '',
  frequencyAmount = 1,
  withdrawBasedOnCheckout,
  withdrawDay = '',
}: Partial<InstallmentPlanResult>) => {
  let installmentsString = `${installmentCount} payments`;
  let withdrawString = '';
  if (depositType === 'first_installment') {
    installmentsString = `${installmentCount} payments`;
  }
  if (depositType === 'percentage') {
    installmentsString = `${installmentCount} equal payments`;
  }
  const frequency = joinFrequencyValues(frequencyType, frequencyAmount);
  const frequencyString = getFrequencyString(frequency || '');
  if (!withdrawBasedOnCheckout) {
    withdrawString = getWithdrawString(frequency || '', withdrawDay);
  }
  return `${installmentsString}, ${frequencyString} ${withdrawString}`;
};
