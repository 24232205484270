import * as React from 'react';

import './RadioGroup.scss';

export interface Props {
  label?: string | JSX.Element;
  name: string;
  options?: RadioOption[] | null;
  onRadioSelect?: (selected: RadioOption) => void;
}

export interface RadioOption {
  label: string | JSX.Element;
  value: string | number;
}

export const RadioGroup = ({ label, name, options, onRadioSelect }: Props) => {
  const [selectedRadio, setSelectedRadio] = React.useState<RadioOption | null>(null);

  React.useEffect(() => {
    if (onRadioSelect) {
      onRadioSelect(selectedRadio as RadioOption);
    }
  }, [onRadioSelect, selectedRadio]);

  return (
    <fieldset className="radio-group sui-border-none">
      {label && <legend className="sui-label">{label}</legend>}
      {options?.map((option) => (
        <div key={`${option.label}-${option.value}`} className="sui-my-2">
          {onRadioSelect ? (
            <>
              <input
                className="sui-mr-1"
                onChange={() => setSelectedRadio(option as RadioOption)}
                type="radio"
                id={`${option.label}-${option.value}`}
                name={name}
                value={option.value}
              />
              <label className="sui-body sui-ml-1" htmlFor={`${option.label}-${option.value}`}>
                {option.label}
              </label>
            </>
          ) : (
            <>
              <input
                className="sui-mr-1"
                type="radio"
                id={`${option.label}-${option.value}`}
                name={name}
                value={option.value}
              />
              <label className="sui-body sui-ml-1" htmlFor={`${option.label}-${option.value}`}>
                {option.label}
              </label>
            </>
          )}
        </div>
      ))}
    </fieldset>
  );
};
