import { GapMedicalComponent, MultiOfferWrapperElementComponent, SeasonInterruptionComponent } from '@vertical-insure/web-components/legacy/react';
import { OrderLineItem } from 'core/api';
import { VERTICAL_INSURE_CLIENT_ID } from 'core/constants';
import ENVIRONMENT from 'core/environment';
import { InsuranceOfferQuoteItem, InsuranceOfferState } from 'core/types';
import { DateFormat, DateService } from 'frontend-toolkit';
import React from 'react';
import { useFormFieldResultStateSelector, useFormStateSelector, useOrderStateSelector } from 'state/order/orderSlice';
import { useHouseholdPeopleSelector, useUserSelector } from 'state/user/userSlice';
import { AddonDisplayComponentProps, findFormFieldResult, getRequiredAddonParticipantFields } from './AddonDisplay';

const CLIENT_ID = VERTICAL_INSURE_CLIENT_ID[ENVIRONMENT];
const applicableInsuranceFeeCategories = ['Registration Fee', 'Membership Fee'];

enum InsuranceProduct {
  SEASON_INTERRUPTION = 'season-interruption',
  GAP_MEDICAL = 'gap-medical'
}

const getInsuranceProductCode = (partnerOfferingProductCode: string): InsuranceProduct | undefined => {
  switch (partnerOfferingProductCode) {
    case 'gap-medical': return InsuranceProduct.GAP_MEDICAL;
    case 'registration-cancellation': return InsuranceProduct.SEASON_INTERRUPTION;
    default: return undefined;
  }
}

/**
 * Calculates the insurable amount for a participant and the participant_group related fees for applicable fee categories.
 * @param baseRegistrationFee 
 * @param formResultId 
 * @param lineItems 
 * @returns number
 */
const calculateInsurableAmount = (baseRegistrationFee: number, formResultId: number, lineItems: OrderLineItem[]) => {
  return lineItems.reduce((partialSum, lineItem) => {
    // sum up adjustment line items if applicable to the insurable amount
    if (lineItem.externalType === 'adjustment' && lineItem.sourceType === 'form_result' && lineItem.sourceId === formResultId && lineItem.category && applicableInsuranceFeeCategories.includes(lineItem.category)) {
      const adjustmentTotalAmount = lineItems.reduce((adjustmentPartialSum, adjustmentLineItem) => {
        // sum all the adjustment fees or credits associated with the specific adjustment
        if (adjustmentLineItem.sourceType === 'adjustment' && adjustmentLineItem.sourceId === lineItem.externalId) {
          return adjustmentPartialSum + adjustmentLineItem.amount;
        }

        return adjustmentPartialSum;
      }, lineItem.amount);

      return partialSum + adjustmentTotalAmount;
    }

    // sum up discounts on registration fees associated with a group
    if (lineItem.sourceType === 'participant_group' && (lineItem.externalType === 'automatic_discount' || lineItem.externalType === 'coupon_code_discount')
      && lineItem.scopeType === 'form_result' && lineItem.scopeId === formResultId) {
      return partialSum + lineItem.amount;
    }

    return partialSum;
  }, baseRegistrationFee);
}

const InsuranceAddonDisplay = ({ formPartnerOffering, offerStateChange, emptyOfferError }: AddonDisplayComponentProps) => {
  const orderState = useOrderStateSelector();
  const formState = useFormStateSelector();
  const user = useUserSelector();
  const formFieldResults = useFormFieldResultStateSelector();
  const householdPeople = useHouseholdPeopleSelector();

  const insuranceProductCode = getInsuranceProductCode(formPartnerOffering.offering.productCode);

  const itemsForQuote = React.useMemo(() => {
    if (!orderState || !formFieldResults) {
      return []
    };

    const participantGroupLineItems = orderState?.orderLineItems.filter(item => {
      if (item.type === 'registration_fee' && item.sourceId !== null && item.sourceType === 'form_result' && item.externalType === 'participant_group') {
        const isWaitlisted = orderState?.orderLineItems.findIndex(lineItem => lineItem.externalType === 'waitlist_discount' && lineItem.sourceId === item.sourceId && lineItem.sourceType === item.sourceType) > -1;
        if (isWaitlisted) {
          return false;
        }
        return true;
      }
      return false;
    }) || [];

    const itemsForQuote: InsuranceOfferQuoteItem[] = [];
    participantGroupLineItems.forEach(lineItem => {
      const formFieldResult = findFormFieldResult(Number(lineItem.sourceId), formFieldResults);

      if (formFieldResult !== undefined) {
        const participant = getRequiredAddonParticipantFields(formFieldResult);

        // if we dont have a birth date field on the form, we will try and find the birth date from the household person associated with the line item
        if (!participant.birthDate) { 
          const householdPersonId = formFieldResult?.householdPersonId;
          const householdPerson = householdPeople?.find((p) => p.id === householdPersonId);
          participant.birthDate = householdPerson?.person.birthdate ?? ''
        }

        itemsForQuote.push({
          ...lineItem,
          participant,
          insurableAmount: calculateInsurableAmount(lineItem.amount, formFieldResult.formResultId, orderState.orderLineItems)
        });
      }
    });

    return itemsForQuote;
  }, [orderState, formFieldResults, householdPeople]);

  return (
    <MultiOfferWrapperElementComponent
      product={insuranceProductCode}
      clientId={CLIENT_ID}
      offerStateChange={(e: unknown) => offerStateChange((e as CustomEvent<InsuranceOfferState[]>).detail)}
      emptyOfferError={emptyOfferError}
      data-testid={`${formPartnerOffering.offering.productCode}-multi-offer`}
      hideOnError={true}
    >
      {insuranceProductCode === 'gap-medical' ? itemsForQuote?.map(item => (
        <GapMedicalComponent
          key={item?.id}
          data-context-name={formState?.organization?.name}
          data-context-event={formState?.name}
          data-associated-line-item={item?.id}
          customerEmailAddress={user?.email}
          customerFirstName={user?.firstName}
          customerLastName={user?.lastName}
          customerPostalCode={item?.participant.zip}
          customerStreetAddress={item?.participant.streetAddress}
          customerCity={item?.participant.city}
          coverageType={formState?.sport?.name}
          coverageStartDate={DateService.dateToString(DateService.addDaysToDate(2, new Date()), DateFormat.YYYY_MM_DD)}
          coverageEndDate={DateService.dateToString(DateService.addDaysToDate(364, new Date()), DateFormat.YYYY_MM_DD)}
          participantFirstName={item?.participant.firstName}
          participantLastName={item?.participant.lastName}
          participantBirthDate={item?.participant.birthDate}
          participantState={item?.participant.stateCode}
          coverageContextDescription={item?.name}
        />
      )) : itemsForQuote?.filter(item => item.insurableAmount > 0).map(item => (
        <SeasonInterruptionComponent
          key={item.id}
          data-associated-line-item={item?.id}
          data-context-name={formState?.organization?.name}
          data-context-event={formState?.name}
          customerEmailAddress={user?.email}
          customerFirstName={user?.firstName}
          customerLastName={user?.lastName}
          customerPostalCode={item.participant.zip}
          customerStreetAddress={item.participant.streetAddress}
          customerCity={item?.participant.city}
          eventStartDate={DateService.dateToString(DateService.addDaysToDate(2, new Date()), DateFormat.YYYY_MM_DD)}
          eventEndDate={DateService.dateToString(DateService.addDaysToDate(364, new Date()), DateFormat.YYYY_MM_DD)}
          participantFirstName={item.participant.firstName}
          participantLastName={item.participant.lastName}
          insurableAmount={item.insurableAmount}
          coverageContextDescription={item.name}
        />
      ))}
    </MultiOfferWrapperElementComponent>
  );
}

export default InsuranceAddonDisplay;
