import * as React from 'react';
import { Modal } from '@teamsnap/teamsnap-ui';
import { LabelButton } from '@teamsnap/snap-ui';

import './PayWithCashModal.scss';

interface Props {
  onClose(cash: boolean): void;
  show: boolean;
}

export const PayWithCashModal = ({ onClose, show }: Props) => {
  const modalStyles = {
    borderRadius: 5,
    minWidth: 360,
    maxWidth: 360,
    padding: 40,
    paddingBottom: 32,
  };

  return (
    <Modal
      testId="Installments--PayWithCashModal"
      heading={
        <div className="sui-flex sui-justify-center sui-m-0">
          <span className="sui-heading-md" data-testid="PayWithCashModal--heading">Pay balance via cash</span>
        </div>
      }
      show={show}
      style={modalStyles}
    >
      <div className="sui-py-4">
        <div className="sui-body sui-text-center sui-py-2">
          Please submit your cash payment directly to your organization.
        </div>
      </div>

      <div className="sui-pt-2">
        <LabelButton
          labelText="Choose another payment method"
          sentiment="default"
          size="default"
          variantType="primary"
          className="sui-mb-1 sui-w-full"
          onClick={() => onClose(false)}
          data-testid="PayWithCahModal--choose-payment"
        />

        <LabelButton
          labelText="Continue to pay via cash"
          sentiment="default"
          size="default"
          variantType="tertiary"
          className="sui-w-full"
          onClick={() => onClose(true)}
          data-testid="PayWithCahModal--cash-payment"
        />
      </div>
    </Modal>
  );
};
