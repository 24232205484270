/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormResultUpdateBodyInterface = {
    status?: FormResultUpdateBodyInterface.status;
    waitlistStatus?: FormResultUpdateBodyInterface.waitlistStatus | null;
};

export namespace FormResultUpdateBodyInterface {

    export enum status {
        IN_PROGRESS = 'in_progress',
        SUBMITTED = 'submitted',
        IN_CART = 'in_cart',
        CANCELED = 'canceled',
        DELETED = 'deleted',
        CHECKOUT = 'checkout',
    }

    export enum waitlistStatus {
        WAITLISTED = 'waitlisted',
        OFFER_EXTENDED = 'offer_extended',
        OFFER_ACCEPTED = 'offer_accepted',
        ENROLLED = 'enrolled',
        RELEASED = 'released',
    }


}

