/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateHouseholdPerson = {
    firstName: string;
    lastName: string;
    gender: CreateHouseholdPerson.gender | null;
    birthdate: string;
    email?: string;
};

export namespace CreateHouseholdPerson {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

