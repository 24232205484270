/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormResultInterface = {
    id: number;
    insertedAt?: string;
    updatedAt?: string;
    orderId: number | null;
    formId: number;
    userId: number | null;
    householdPersonId: number;
    participantGroupId: number;
    status: FormResultInterface.status;
    checkoutStartTime: string | null;
    waitlistStatus: FormResultInterface.waitlistStatus | null;
    formWaiverResults: Array<{
        id: number;
        formWaiverTitle?: string;
        formResultId: number;
        formWaiverId: number;
        content: string;
        signatureType: 'checkbox_accept' | 'initials' | 'full_name' | 'e-signature';
        signature: string | null;
        userIpAddress: string | null;
        insertedAt: string;
        formWaiverFile?: {
            originalFileName: string | null;
            downloadUrl: string | null;
        };
    }>;
};

export namespace FormResultInterface {

    export enum status {
        IN_PROGRESS = 'in_progress',
        SUBMITTED = 'submitted',
        IN_CART = 'in_cart',
        CANCELED = 'canceled',
        DELETED = 'deleted',
        CHECKOUT = 'checkout',
    }

    export enum waitlistStatus {
        WAITLISTED = 'waitlisted',
        OFFER_EXTENDED = 'offer_extended',
        OFFER_ACCEPTED = 'offer_accepted',
        ENROLLED = 'enrolled',
        RELEASED = 'released',
    }


}

