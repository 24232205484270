/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Button } from '@teamsnap/teamsnap-ui';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

// Local Imports
import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';
import { useAppDispatch } from 'state/hooks';

import { updatePaymentMethod, usePaymentMethodStateSelector, usePaymentStateErrorSelector } from 'state/payment/paymentSlice';
import { useOrderStateSelector, useFormIdSelector } from 'state/order/orderSlice';

import './Payment.scss';
import { useUserSelector } from 'state/user/userSlice';
import { useInstallmentStateSelector } from 'state/installments/installmentsSlice';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { ACCOUNTS_URL } from 'core/constants';

interface FieldState {
  value: string;
  error?: string;
}

interface Props {
  orderId?: number;
}

export const UpdatePaymentMethod = ({ orderId }: Props) => {
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<FieldState>({ value: '' });
  const [lastName, setLastName] = React.useState<FieldState>({ value: '' });
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const dispatch = useAppDispatch();
  const formRef = React.useRef<HTMLFormElement>(null);
  const stripe = useStripe();
  const elements = useElements();
  const orderData = useOrderStateSelector();
  const paymentMethod = usePaymentMethodStateSelector();
  const installments = useInstallmentStateSelector();
  const errors = usePaymentStateErrorSelector();
  const user = useUserSelector();
  const formId = useFormIdSelector();

  const showBackButton = !!installments || !!formId;
  const handleBackButton = () => {
    if (formId) {
      window.location.href = `${ACCOUNTS_URL}/orders`;
    }
  };

  React.useEffect(() => {
    if (!paymentMethod) return;
    if (paymentMethod?.id) {
      setIsProcessing(false);
      window.location.href = `${ACCOUNTS_URL}/orders?updated-payment-method=success`;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  React.useEffect(() => {
    if (errors && errors.length > 0 && typeof errors[0] != 'string') {
      setIsProcessing(false);
      setErrorMessage(errors[0].message);
    }
    else {
      setErrorMessage(undefined);
    }
  }, [errors]);

  const submitPaymentForm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!firstName.value) {
      setFirstName((prev) => ({ ...prev, error: 'First name cannot be blank' }));
    }
    if (!lastName.value) {
      setLastName((prev) => ({ ...prev, error: 'Last name cannot be blank' }));
    }

    if (!firstName.value || !lastName.value) {
      setIsProcessing(false);
      return;
    }

    // eslint-disable-next-line
    const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
      // eslint-disable-next-line
      // @ts-ignore
      elements,
      params: {
        billing_details: {
          name: `${firstName.value} ${lastName.value}`,
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    }

    if (!error && orderData && orderData.paymentAccountId) {
      dispatch(
        updatePaymentMethod({
          orderId: orderData.id,
          paymentAccountId: orderData.paymentAccountId,
          paymentMethodToken: stripePaymentMethod.id,
          paymentMethodType: stripePaymentMethod.type,
          returnUrl: window.location.href,
        })
      );
    }
  };

  return (
    <ActionContainer
      submitting={false}
      removeContentFormatting={true}
      header={
        <Header
          title="Change payment method"
          navigation={
            showBackButton && (
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={handleBackButton}
              />
            )
          }
          //   rightIcon={<CartButton />}
          profileName={`${user?.firstName} ${user?.lastName}`}
        />
      }
      extraFooter={<></>}
      footer={
        <div className="t:sui-flex t:items-center t:sui-justify-between">
          {showBackButton ? (
            <Button
              key="add-another-member"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
              icon="arrow-left"
              iconPosition="left"
              onClick={handleBackButton}
            >
              Back
            </Button>
          ) : (
            <span />
          )}
          <Button
            key="check-out"
            color="primary"
            mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
            icon={isProcessing ? 'loader' : undefined}
            iconPosition="right"
            onClick={(e) => submitPaymentForm(e)}
            isDisabled={isProcessing}
            testId="UpdatePaymentMethod-save-changes-button"
          >
            Save Changes
          </Button>
        </div>
      }
      children={
        <div data-testid="payment-view">
          {errorMessage && (
            <div className="sui-px-3 sui-mt-2 t:sui-px-0">
              <ErrorMessage message={errorMessage} />
            </div>
          )}

          <div className="sui-bg-white sui-py-2 sui-px-3 sui-mt-2">
            <form ref={formRef}>
              <PaymentElement options={{ fields: { billingDetails: { name: 'never' } } }} />
              <div className="sui-flex sui-w-full">
                <div className="Payment--field sui-w-6/12 sui-mr-0.5">
                  <label className="Payment--label">First Name</label>
                  <div className={`Payment--input ${firstName.error && 'Payment--input-invalid'}`}>
                    <input
                      type="text"
                      inputMode="text"
                      name="firstName"
                      id="firstName"
                      placeholder="John"
                      className="Payment--input-input"
                      onChange={(e) => setFirstName({ value: e.target.value })}
                      value={firstName.value}
                    />
                    {firstName.error && <div className="Payment--error-message">{firstName.error}</div>}
                  </div>
                </div>
                <div className="Payment--field  sui-w-6/12 sui-ml-1">
                  <label className="Payment--label">Last Name</label>
                  <div className={`Payment--input ${lastName.error && 'Payment--input-invalid'}`}>
                    <input
                      type="text"
                      inputMode="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Doe"
                      className="Payment--input-input"
                      onChange={(e) => setLastName({ value: e.target.value })}
                      value={lastName.value}
                    />
                    {lastName.error && <div className="Payment--error-message">{lastName.error}</div>}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
    />
  );
};
