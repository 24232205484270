/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatePerson = {
    firstName: string;
    lastName: string;
    gender: CreatePerson.gender | null;
    birthdate: string;
    email: string;
};

export namespace CreatePerson {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

