/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Form,
  FormMessageInterface,
  FormResultInterface,
  FormResultsResponse,
  FormResultsService,
  FormsService,
  Order,
  OrdersService,
  RegistrationConfirmationService,
  UpdateOrder
} from 'core/api';
import { AsyncState } from 'core/types';

import {
  asyncFulfilledNoDataReducer,
  asyncFulfilledReducer,
  asyncFulfilledSubReducer,
  asyncPendingReducer,
  handleErrors,
  useAppSelector,
} from '../hooks';
import { OrderState } from './types';

const initialState: AsyncState<OrderState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const getOrder = createAsyncThunk('order/get', (orderId: number, { dispatch }) =>
  handleErrors(async () => {
    const order = await OrdersService.getOrder(orderId);

    const lineItem = order?.orderLineItems.find((lineItem) => !!lineItem.scopeId);
    const formId = lineItem?.scopeId as number;
    dispatch(
      orderSlice.actions.updateClockOffset(order?.currentMillis ? order?.currentMillis - new Date().getTime() : 0)
    );
    dispatch(orderSlice.actions.updateFormId(formId));
    dispatch(orderSlice.actions.updateOrgId(order?.organizationId ? +order?.organizationId : null));
    dispatch(getForm(formId));
    dispatch(getFormFieldResults(order?.formResultIds));
    return order;
  })
);

export const getFormFieldResults = createAsyncThunk('formFieldResults/get', (formResultIds: number[], { dispatch }) =>
  handleErrors(async () => {
    if (!formResultIds || formResultIds.length === 0) {
      return;
    }

    const formFieldResults = await FormResultsService.getFormResults(formResultIds.join(','));

    dispatch(
      orderSlice.actions.updateFormFieldResults(formFieldResults)
    );

    return formFieldResults.results;
  })
);

export const getForm = createAsyncThunk('form/get', (formId: number, { dispatch }) =>
  handleErrors(async () => {
    const form = await FormsService.showForm(formId);
    dispatch(orderSlice.actions.updateForm(form));
    return form;
  })
);


export const getOrderConfirmation = createAsyncThunk(
  'order/confirmation',
  ({ orderId, formId }: { orderId: number; formId: number }) =>
    handleErrors(async () => RegistrationConfirmationService.registrationConfirmation(orderId, formId))
);

export const getFormResult = createAsyncThunk('formResult/get', (offerToken: string) =>
  handleErrors(async () => {
    const formResult = await FormResultsService.getFormResultByOfferToken(offerToken);

    return formResult;
  })
);

export const acceptWaitlistOffer = createAsyncThunk(
  'formResult/post/accept-offer',
  (offerToken: string, { dispatch }) =>
    handleErrors(async () => {
      const result = await FormResultsService.waitlistAcceptOffer(offerToken);

      if (!result.order) {
        return result;
      }

      if (result.order.status !== 'no_fees') {
        const lineItem = result.order.orderLineItems.find((lineItem) => !!lineItem.scopeId);
        const formId = lineItem?.scopeId as number;
        dispatch(orderSlice.actions.updateFormId(formId));
        dispatch(orderSlice.actions.updateOrgId(result.order.organizationId ? +result.order.organizationId : null));
        dispatch(orderSlice.actions.updateOrderState(result.order));
        dispatch(getForm(formId));
        dispatch(getFormFieldResults(result.order.formResultIds || []));
      }

      return result;
    })
);

export const releaseFormResult = createAsyncThunk('formResult/post', (formResultId: number) =>
  handleErrors(async () => {
    return await FormResultsService.declineWaitlistOffer(formResultId);
  })
);

export const updateOrder = createAsyncThunk(
  'order/updateOrderLineItems',
  ({
    orderId,
    orderData
  }: {
    orderId: number;
    orderData: UpdateOrder;
  }, { dispatch }) => handleErrors(async () => {

    // keep_existing_installment_plan = true, we will not reset the order installment plans. 
    const updatedOrder = await OrdersService.updateOrder(orderId, true, orderData);
    dispatch(orderSlice.actions.updateOrderState(updatedOrder));

    return updatedOrder;
  })
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateOrderState: (state, action: PayloadAction<Order>) => {
      const newState = {
        ...state.data,
        order: action.payload,
      };
      state.data = newState;
    },
    updateFormId: (state, action: PayloadAction<number>) => {
      const newState = {
        ...state.data,
        formId: action.payload,
      };
      state.data = newState;
    },
    updateOrgId: (state, action: PayloadAction<number | null>) => {
      const newState = {
        ...state.data,
        organizationId: action.payload,
      };
      state.data = newState;
    },
    updateClockOffset: (state, action: PayloadAction<number>) => {
      const newState = {
        ...state.data,
        clockOffset: action.payload,
      };
      state.data = newState;
    },
    updateFormFieldResults: (state, action: PayloadAction<FormResultsResponse>) => {
      const newState = {
        ...state.data,
        formFieldResults: action.payload.results,
      };
      state.data = newState;
    },
    updateForm: (state, action: PayloadAction<Form>) => {
      const newState = {
        ...state.data,
        form: action.payload,
      };
      state.data = newState;
    },
    skipAddons: (state, action: PayloadAction<boolean>) => {
      const newState = {
        ...state.data,
        skipAddons: action.payload,
      };
      state.data = newState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrder.pending, asyncPendingReducer);
    builder.addCase(getOrder.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'order'));

    builder.addCase(getOrderConfirmation.pending, asyncPendingReducer);
    builder.addCase(getOrderConfirmation.fulfilled, (state, action) => {
      const updatedState = {
        ...state.data,
        order: action.payload?.data?.order,
        formResults: action.payload?.data?.formResults as FormResultInterface[],
        formMessage: action.payload?.data?.formMessage as FormMessageInterface,
      };

      state.data = updatedState;
      state.error = action.payload.error;

      if (action.payload.error) {
        state.errors = action.payload.errors || ['Unknown Error'];
      }

      state.processing = false;
    });

    builder.addCase(getFormResult.pending, asyncPendingReducer);
    builder.addCase(getFormResult.fulfilled, (state, action) => asyncFulfilledReducer(state, action));

    builder.addCase(releaseFormResult.pending, asyncPendingReducer);
    builder.addCase(releaseFormResult.fulfilled, asyncFulfilledNoDataReducer);

    builder.addCase(acceptWaitlistOffer.pending, asyncPendingReducer);
    builder.addCase(acceptWaitlistOffer.fulfilled, (state, action) => asyncFulfilledReducer(state, action));

    builder.addCase(getForm.pending, asyncPendingReducer);
    builder.addCase(getForm.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'form'));
    builder.addCase(getFormFieldResults.pending, asyncPendingReducer);
    builder.addCase(getFormFieldResults.fulfilled, (state, action) => asyncFulfilledSubReducer(state, action, 'formFieldResults'));
  },
});

export const { updateFormId, updateOrgId } = orderSlice.actions;

export const useClockOffsetSelector = () => useAppSelector((state) => state.order?.data?.clockOffset);
export const useOrderStateSelector = () => useAppSelector((state) => state.order?.data?.order);
export const useOrderWaitlistStatus = () =>
  useAppSelector((state) => state.order?.data?.order?.waitlistStatus === 'offer_accepted');
export const useOrderProcessingSelector = () => useAppSelector((state) => state.order?.processing);
export const useFormIdSelector = () => useAppSelector((state) => state.order?.data?.formId);
export const useFormStateSelector = () => useAppSelector((state) => state.order?.data?.form);
export const useSkipAddonsSelector = () => useAppSelector((state) => state.order?.data?.skipAddons);
export const useOrgIdSelector = () => useAppSelector((state) => state.order?.data?.organizationId);
export const useFormMessageSelector = () => useAppSelector((state) => state.order?.data?.formMessage);
export const useFormResultsSelector = () => useAppSelector((state) => state.order?.data?.formResults);
export const useFormFieldResultStateSelector = () => useAppSelector((state) => state.order?.data?.formFieldResults);
export const useConfirmationLoadedSelector = () =>
  useAppSelector((state) => Boolean(state.order?.data?.formMessage || state.order?.data?.formResults));

export default orderSlice.reducer;
