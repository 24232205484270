import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';

import './ExpiredWaitlistOffer.scss';

export const ExpiredWaitlistOffer = () => {
  return (
    <div className="ExpiredWaitlistOffer">
      <ActionContainer
        removeContentFormatting={true}
        submitting={false}
        contentStyles={{ backgroundColor: 'white' }}
        header={<Header noProfile title="Offer Expired" />}
      >
        <div className="sui-bg-white sui-py-2 sui-px-3 sui-mt-2">
          <p className="sui-body sui-text-gray-40 sui-mt-2 sui-text-center">The offer has expired.</p>
        </div>
      </ActionContainer>
    </div>
  );
};
