import * as React from 'react';

import { Icon } from '@teamsnap/snap-ui';
import './Accordion.scss';

type Props = {
  title: string;
  children: JSX.Element;
  open?: boolean;
  defaultOpen?: boolean;
  hasDivider?: boolean;
};

export const Accordion = ({ title, children, open, defaultOpen = true, hasDivider = true }: Props) => {
  const [isOpen, setIsOpen] = React.useState(open === undefined ? defaultOpen === true : open);

  React.useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <div className={`accordion sui-bg-white sui-mb-1 ${isOpen ? 'accordion--open' : ''}`}>
      <button
        className="accordion-trigger sui-p-2 sui-flex sui-items-center"
        onClick={() => {
          if (!open) {
            setIsOpen(!isOpen);
          }
        }}
        type="button"
      >
        {isOpen ? <Icon filled name='keyboard_arrow_down' data-testid='accordion-arrow-open' /> : <Icon filled name='chevron_right' data-testid='accordion-arrow-closed' />}
        <span className="sui-heading-sm sui-ml-2" data-testid="accordion-title">
          {title}
        </span>
      </button>
      <div className="accordion-content">
        <div
          className={`sui-border-0 ${hasDivider ? 'sui-border-t sui-border-solid sui-border-gray-5' : null
            } sui-px-1 sui-pb-2`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
