import { Form, Formik, FormikHelpers } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import { loginUser, useLoginEmailSelctor } from 'state/user/userSlice';
import * as Yup from 'yup';
import logoImage from '../../assets/images/logo.svg';

import { ActionContainer } from 'components/shared';
import { FormField, FormFieldType } from 'components/shared/FormField/FormField';
import * as validations from 'core/validations';
import { useAppDispatch, useAppNavigate } from '../../state/hooks';

const schema = Yup.object({
  password: validations.LOGIN_PASSWORD,
});

interface Values {
  password: string;
}

export const Login = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<{ orderId: string }>();
  const email = useLoginEmailSelctor() || '';
  const navigate = useAppNavigate();
  const location = useLocation();

  const onSubmit = async (values: Values, { setFieldError, setSubmitting }: FormikHelpers<Values>) => {
    const { data } = await dispatch(loginUser({ email, password: values.password })).unwrap();

    if (!data?.success) {
      setSubmitting(false);
      setFieldError('password', 'Sorry, we didn’t recognize that password.');
    } else {
      if (location.pathname.indexOf('accept') > -1 || location.pathname.indexOf('decline') > -1) {
        navigate(location.pathname.replace('/login', ''), { replace: true });
      } else {
        navigate(`/order/${orderId}`, { replace: true });
      }
    }
  };

  return (
    <div className="EmailEntry">
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer
            action={handleSubmit}
            actionLabel="Log In"
            title="Order - login"
            submitting={isSubmitting}
            displayBackButton={true}
          >
            <Form>
              <div className="sui-flex sui-justify-center sui-mb-3">
                <img className="logo-image" src={logoImage} alt="TeamSnap" />
              </div>

              <h1 className="sui-heading-md">We know you!</h1>

              <p className="sui-body sui-text-gray-8 sui-mb-3">Please enter your TeamSnap password.</p>

              <FormField type={FormFieldType.PASSWORD_INPUT} label="Password" name="password" placeholder="" />
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
