/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePerson = {
    firstName: string;
    lastName: string;
    gender: UpdatePerson.gender | null;
    birthdate: string;
    email?: string;
};

export namespace UpdatePerson {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

