import * as Yup from 'yup';

const MAX_NAME_LENGTH = 40;

export const EMAIL = Yup.string()
  .email('Email format is invalid.')
  .required('Email is required.')
  .max(254, 'Max email length is 254 characters');

export const FIRST_NAME = Yup.string()
  .required('First name is required.')
  .max(MAX_NAME_LENGTH, `First name must be at most ${MAX_NAME_LENGTH} characters.`);

export const LAST_NAME = Yup.string()
  .required('Last name is required.')
  .max(MAX_NAME_LENGTH, `Last name must be at most ${MAX_NAME_LENGTH} characters.`);

export const PHONE_NUMBER = Yup.string()
  .required('Phone number is required.')
  .max(10, 'Phone number must be at most 10 characters.');

export const PASSWORD = Yup.string()
  .required('Password is required.')
  .min(8, 'Password must contain at least 8 characters.')
  .max(255, 'Password cannot be more than 255 characters.')
  .notOneOf([Yup.ref('email')], 'Password cannot be same as email.');

export const BIRTH_DATE = Yup.string().required('Birthdate is required.');

export const GENDER = Yup.string().required('Gender is required.');

export const LOGIN_PASSWORD = Yup.string().required('Password is required.');

export const TERMS_AND_PRIVACY = Yup.bool().oneOf([true], 'You must first accept the Terms & Service.');
export const NEWSLETTER = Yup.bool();
