import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Cell, Grid, Loader } from '@teamsnap/teamsnap-ui';

import { ActionContainer } from 'components/shared/ActionContainer';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import NotFound from './NotFound';

import { getInstallmentOptions, useInstallmentPreviewStateSelector } from 'state/installments/installmentsSlice';
import {
  acceptWaitlistOffer,
  getFormResult,
  getOrder,
  releaseFormResult,
  useFormStateSelector,
  useOrderStateSelector,
} from 'state/order/orderSlice';
import PaymentPage from './PaymentPage/PaymentPage';
import UpdatePaymentPage from './UpdatePaymentPage/UpdatePaymentPage';

import { Icon } from '@teamsnap/snap-ui';
import { FormResultInterface, WaitlistAcceptOfferInterface } from 'core/api';
import { useAmplitude } from 'state/hooks/useAmplitude';
import { loadHouseholdPeople, loadUser, useUserStateSelector } from 'state/user/userSlice';
import AddonsPage from './AddonsPage/AddonsPage';
import AgreementsPage from './AgreementsPage/AgreementsPage';
import { ConfirmationPage } from './ConfirmationPage/ConfirmationPage';
import InstallmentsPage from './InstallmentsPage/InstallmentsPage';
import { Email, Login, NewUser } from './User';

const LoadingPage = () => (
  <ActionContainer title="Order">
    <Grid isWithGutter>
      <Cell mods="u-flex u-flexJustifyCenter u-spaceBottomMd">
        <Loader type="spin" text="Loading..." />
      </Cell>
    </Grid>
  </ActionContainer>
);

const ErrorNotice = () => (
  <ActionContainer contentStyles={{ backgroundColor: '#fff' }}>
    <div className="sui-flex sui-flex-col sui-mx-2 sui-items-center sui-py-6">
      <Icon filled name="warning" size="xl" style={{ color: '#FCD603' }} />
      <div className="sui-mt-2 sui-font-size-6 sui-text-center">Oh no! It looks like something went wrong. </div>
      <div className="sui-mt-6 sui-font-size-5 sui-text-center">
        Please try again or contact TeamSnap Support for help.
      </div>
    </div>
  </ActionContainer>
);

export const Root = () => {
  const { orderId, offerToken, path } = useParams<{ orderId: string; offerToken: string; path: string }>();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const orderState = useOrderStateSelector();
  const formState = useFormStateSelector();
  const userState = useUserStateSelector();
  const { amplitudeIdentify, amplitudeTrack } = useAmplitude();

  const installmentPreviewData = useInstallmentPreviewStateSelector();
  const loading = !orderState?.id || !installmentPreviewData;

  const location = useLocation();

  const [shouldDisplayErrorPage, setShouldDisplayErrorPage] = useState(false);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrder(+orderId));
    }
  }, [dispatch, orderId, path]);

  useEffect(() => {
    if (userState.data?.user) {
      const { uciUuid, email } = userState.data?.user || {};
      if (uciUuid) {
        amplitudeIdentify(uciUuid, email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.data?.user]);

  useEffect(() => {
    if (userState.data?.userLoadCalled && userState.data?.user) {
      amplitudeTrack.screenViewed(path, orderId);
    }
    // removing dependencies since it was causing duplicates of screen event calls for amplitudeTrack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, userState.data?.userLoadCalled]);

  useEffect(() => {
    if (!userState.data?.user) {
      dispatch(loadUser());
    }
  }, [dispatch, userState.data?.user]);

  useEffect(() => {
    // checking pathname to make sure it doesnt include login because this will refire due to the dependencies
    const shouldRunMutations = offerToken && userState.data?.user && !location.pathname.includes('login');

    if (!shouldRunMutations) return;

    if (location.pathname.includes('accept')) {
      dispatch(acceptWaitlistOffer(offerToken))
        .unwrap()
        .then((response) => {
          const offerResponse = response?.data as WaitlistAcceptOfferInterface;
          if (offerResponse?.order?.status === 'no_fees') {
            navigate(`/order/${offerResponse.order.id}/confirmation`);
          } else if (offerResponse.expired) {
            navigate('/expired', { replace: true });
          } else if (offerResponse.unauthorized) {
            navigate('/', { replace: true });
          }
        })
        .catch((e) => {
          console.error(e);
          setShouldDisplayErrorPage(true);
        });
    } else if (location.pathname.includes('decline')) {
      dispatch(getFormResult(offerToken))
        .unwrap()
        .then((response) => {
          const formResult = response?.data as FormResultInterface;
          if (!formResult) {
            navigate('/expired', { replace: true });
          } else if (formResult.userId !== userState.data?.user?.id) {
            navigate('/', { replace: true });
          } else {
            dispatch(releaseFormResult(formResult.id))
              .unwrap()
              .then((response) => {
                const formResult = response?.data;
                if (!formResult) {
                  setShouldDisplayErrorPage(true);
                } else {
                  navigate('/reject', { replace: true, state: { orgName: response.data?.orgName } });
                }
              });
          }
        })
        .catch((e) => {
          console.error(e);
          setShouldDisplayErrorPage(true);
        });
    }
  }, [dispatch, userState.data?.user, offerToken, path, location, navigate]);

  useEffect(() => {
    if (orderState?.id && userState.data?.user) {
      dispatch(loadHouseholdPeople());
      dispatch(getInstallmentOptions(+orderState.id));
    }
  }, [dispatch, orderState?.id, userState?.data?.user]);

  useEffect(() => {
    const getOrderId = () => {
      if (orderState !== undefined && !orderId) {
        return orderState.id;
      }

      return orderId;
    };

    if (!loading && !path && getOrderId() && formState) {
      if (orderState?.status === 'pending_payment') {
        navigate(`/order/${getOrderId()}/payment`, { replace: true });
        return;
      }

      if (orderState?.status === 'waiting_for_payment') {
        navigate(`/order/${getOrderId()}/confirmation`, { replace: true });
        return;
      }

      if (installmentPreviewData.length > 0) {
        navigate(`/order/${getOrderId()}/installments`, { replace: true });
        return;
      }

      if (formState?.availableAddons && formState?.availableAddons.length > 0) {
        navigate(`/order/${getOrderId()}/addons`, { replace: true });
        return;
      }

      navigate(`/order/${getOrderId()}/payment`, { replace: true });
    }
  }, [installmentPreviewData?.length, loading, navigate, orderId, path, orderState, formState]);

  if (shouldDisplayErrorPage) {
    return <ErrorNotice />;
  }

  if (!userState.data?.userLoadCalled) {
    return <LoadingPage />;
  }

  if (userState.data?.user) {
    if (loading) {
      return <LoadingPage />;
    }
    switch (path) {
      case 'payment':
        return <PaymentPage />;
      case 'update-payment-method':
        return <UpdatePaymentPage />;
      case 'confirmation':
        return <ConfirmationPage />;
      case 'installments':
        return <InstallmentsPage />;
      case 'agreements':
        return <AgreementsPage />;
      case 'addons':
        return <AddonsPage />;
      default:
        return <NotFound />;
    }
  } else {
    switch (path) {
      case 'login':
        return userState.data?.loginEmail ? <Login /> : <Email />;
      case 'newUser':
        return userState.data?.loginEmail ? <NewUser /> : <Email />;
      default:
        return <Email />;
    }
  }
};
