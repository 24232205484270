import { EnvironmentUtilities } from 'frontend-toolkit';
import ENVIRONMENT from './environment';

const envUtils = new EnvironmentUtilities(window.location.host);

export const IS_PROD = ENVIRONMENT.indexOf('prod') !== -1;

export const APP_URL = {
  local: 'http://localhost:3006',
  test: 'http://localhost:3006',
  staging: `https://${envUtils.getStagingHost('order', 'staging.order.teamsnap.com')}`,
  prod: 'https://order.teamsnap.com',
}[ENVIRONMENT];

export const API_URL = {
  local: 'http://localhost:5620',
  test: 'http://localhost:5620',
  staging: `https://${envUtils.getStagingHost('order-api', 'staging-order-api-prime.teamsnap.com')}`,
  prod: 'https://order-api.teamsnap.com',
}[ENVIRONMENT];

export const REGISTRATION_URL = {
  local: 'http://localhost:3000',
  test: 'http://localhost:3000',
  staging: `https://${envUtils.getStagingHost('registration', 'staging.registration.teamsnap.com')}`,
  prod: 'https://registration.teamsnap.com',
}[ENVIRONMENT];

export const ACCOUNTS_URL = {
  local: 'http://localhost:9004',
  test: 'http://localhost:9004',
  staging: `https://${envUtils.getStagingHost('accounts', 'staging-prime.accounts.teamsnap.com')}`,
  prod: 'https://accounts.teamsnap.com',
}[ENVIRONMENT];

export const TEAMSNAP_URL = {
  local: 'http://localhost:3001',
  test: 'http://localhost:3001',
  staging: `https://${envUtils.getStagingHost('go', 'staging-classic.teamsnap.com')}`,
  prod: 'https://go.teamsnap.com',
}[ENVIRONMENT];

export const STRIPE_CLIENT_KEY = {
  local: 'pk_test_51Dq5BXH3v9ZxQViIRIr5T0mX3fZxXXYszWlkHrurMnaCQq9UWoXw5jaX6OK7QkG9pALgvQ7zL8zNYXQRiVJKalAn00GyEUjvGu',
  staging:
    'pk_test_51Dq5BXH3v9ZxQViIRIr5T0mX3fZxXXYszWlkHrurMnaCQq9UWoXw5jaX6OK7QkG9pALgvQ7zL8zNYXQRiVJKalAn00GyEUjvGu',
  prod: 'pk_live_VOrEglkce2zbFN3dJ6aEmZ1c',
}[ENVIRONMENT];

export const SENTRY_URL = 'https://edc440fbe85448bab958d4fa3f962987@o126035.ingest.sentry.io/4504686249639936';

export const FIREBASE_API_KEY = {
  local: 'AIzaSyBX7rPA7HT4J2VaWv2EjqRtnX7rxQpchKw',
  staging: 'AIzaSyBX7rPA7HT4J2VaWv2EjqRtnX7rxQpchKw',
  prod: 'AIzaSyDY6y23k8gdOoyfdk_RBaGlBaUam5vuPEk',
}[ENVIRONMENT];

export const FIREBASE_AUTH_DOMAIN = {
  local: 'live-dev-1-1.firebaseapp.com',
  staging: 'live-dev-1-1.firebaseapp.com',
  prod: 'live-prod-1-1.firebaseapp.com',
}[ENVIRONMENT];

export const FIREBASE_DB_URL = {
  local: 'https://live-dev-1-1.firebaseio.com',
  staging: 'https://live-dev-1-1.firebaseio.com',
  prod: 'https://live-prod-1-1.firebaseio.com',
}[ENVIRONMENT];

export const FIREBASE_PROJECT_ID = {
  local: 'live-dev-1-1',
  staging: 'live-dev-1-1',
  prod: 'live-prod-1-1',
}[ENVIRONMENT];

export const FIREBASE_STORAGE_BUCKET = {
  local: 'live-dev-1-1.appspot.com',
  staging: 'live-dev-1-1.appspot.com',
  prod: 'live-prod-1-1.appspot.com',
}[ENVIRONMENT];

export const FIREBASE_MESSAGING_SENDER_ID = {
  local: '979924482870',
  staging: '979924482870',
  prod: '81859586434',
}[ENVIRONMENT];

export const FIREBASE_APP_ID = {
  local: '1:979924482870:web:c7057b71eaa444834a1197',
  staging: '1:979924482870:web:c7057b71eaa444834a1197',
  prod: '1:81859586434:web:e28c4512af924b2e25879b',
}[ENVIRONMENT];

export const FIREBASE_MEASURE_ID = {
  local: 'G-QRD7FK554T',
  staging: 'G-QRD7FK554T',
  prod: 'G-ZY5YHG42FW',
}[ENVIRONMENT];

export const VERTICAL_INSURE_CLIENT_ID = {
  local: 'test_FC86VW2K55T3UOP9KFTYFILXEVRFLKUQ',
  staging: 'test_FC86VW2K55T3UOP9KFTYFILXEVRFLKUQ',
  prod: 'live_7ZYDW0TEJOV5MV30QYGKHQL9OL1MK115'
}

export const CHECKOUT_EXPIRATION_MINUTES = 10;
