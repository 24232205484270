"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagService = void 0;
var featureFlagService = /** @class */ (function () {
    function featureFlagService() {
    }
    featureFlagService.prototype.isFeatureEnabledForOrg = function (config, environment, organizationId) {
        var _a;
        if (!config || !(config === null || config === void 0 ? void 0 : config.enabled)) {
            return true;
        }
        if ((environment === null || environment === void 0 ? void 0 : environment.indexOf('staging')) !== -1 || (environment === null || environment === void 0 ? void 0 : environment.indexOf('development')) !== -1) {
            return (_a = config.allowedOrgsInStaging) === null || _a === void 0 ? void 0 : _a.includes(+organizationId);
        }
        else if ((environment === null || environment === void 0 ? void 0 : environment.indexOf('prod')) !== -1) {
            return config.allowedOrgsInProd.includes(+organizationId);
        }
        else {
            return config.allowedEnvironments.includes(environment);
        }
    };
    featureFlagService.prototype.isFeatureEnabledForDivision = function (config, environment, rootDivisionId) {
        var _a;
        if (!config || !(config === null || config === void 0 ? void 0 : config.enabled)) {
            return true;
        }
        if ((environment === null || environment === void 0 ? void 0 : environment.indexOf('staging')) !== -1 || (environment === null || environment === void 0 ? void 0 : environment.indexOf('development')) !== -1) {
            return (_a = config.allowedDivisionsInStaging) === null || _a === void 0 ? void 0 : _a.includes(+rootDivisionId);
        }
        else if ((environment === null || environment === void 0 ? void 0 : environment.indexOf('prod')) !== -1) {
            return config.allowedDivisionsInProd.includes(+rootDivisionId);
        }
        else {
            return config.allowedEnvironments.includes(environment);
        }
    };
    return featureFlagService;
}());
exports.FeatureFlagService = new featureFlagService();
