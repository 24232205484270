import { useEffect, useState } from 'react'
import { Tag } from '@teamsnap/teamsnap-ui';
import { useCountdown } from './useCountdown'
import './CountDownExpiration.scss'

interface CountDownExpirationProps {
  targetDate: number
  isCountDownEnded: boolean
  onFinishCountDown: () => void
}

export const CountDownExpiration = ({ targetDate, isCountDownEnded, onFinishCountDown }: CountDownExpirationProps) => {
  const [minutes, seconds] = useCountdown(targetDate)
  const [displayCountDown, setDisplayCountDown] = useState('0m 0s')

  useEffect(() => {
    setDisplayCountDown(`${minutes}m ${seconds}s`)
    if (minutes + seconds <= 0) {
      onFinishCountDown()
    }

    if (isCountDownEnded) {
      setDisplayCountDown('0m 0s')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds])

  return (
    <Tag mods="CountDownExpiration--tag" text={displayCountDown} />
  )
}
