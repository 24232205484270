"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateService = exports.DateFormat = void 0;
/**
 * @enum DateFormat
 * This enum provides the different formatting options supported by the `dateToString` method of DateService.
 */
var DateFormat;
(function (DateFormat) {
    DateFormat[DateFormat["YYYY_MM_DD"] = 0] = "YYYY_MM_DD";
    DateFormat[DateFormat["MM_DD_YYYY"] = 1] = "MM_DD_YYYY";
    DateFormat[DateFormat["SHORT_DATE"] = 2] = "SHORT_DATE";
    DateFormat[DateFormat["LONG_DATE"] = 3] = "LONG_DATE";
    DateFormat[DateFormat["DATE_WITH_TIME_AND_TIMEZONE"] = 4] = "DATE_WITH_TIME_AND_TIMEZONE";
    DateFormat[DateFormat["DD_MM_YYYY"] = 5] = "DD_MM_YYYY";
    DateFormat[DateFormat["DATE_WITH_TIME"] = 6] = "DATE_WITH_TIME";
    DateFormat[DateFormat["TIME_WITH_DATE"] = 7] = "TIME_WITH_DATE";
})(DateFormat = exports.DateFormat || (exports.DateFormat = {}));
/**
 * @class DateService
 * used to work with Dates, leverages INTL methods (browser only) and should be used instead of moment or time.js
 */
var DateService = /** @class */ (function () {
    function DateService() {
    }
    /**
     * @method dateToString
     * @description Formats a given date with the provided formatter.
     * @param date A date object that you wish to format.
     * @param format @enum DateFormat
     * @returns string
     */
    DateService.dateToString = function (date, format) {
        if (!date)
            return '';
        var formatter = DateService.formatters[format];
        if (!formatter) {
            return 'Err: unspecified formatter.';
        }
        return formatter(date);
    };
    DateService.format = function (date, format) {
        if (!date)
            return '';
        var formatter = DateService.formatters[format];
        if (!formatter) {
            return 'Err: unspecified formatter.';
        }
        return formatter(date);
    };
    /**
     * @method stringToDate
     * @description Converts a string into a date.
     * @param dateString A string in date format that you need to convert to a js Date.
     * @param format @enum DateFormat
     * @returns Date
     */
    DateService.stringToDate = function (dateString, format) {
        var date = new Date(dateString.length === 19 ? dateString + '+00:00' : dateString);
        if (format === DateFormat.YYYY_MM_DD) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
        else if (format === DateFormat.MM_DD_YYYY) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
        return date;
    };
    /**
     * @method adjustForTimezone
     * @description Adjusts a UTC date to display with offset hours.
     * @param date The UTC date requiring conversion.
     * @returns Date
     */
    DateService.adjustForTimezone = function (date) {
        var timeOffsetInMS = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date;
    };
    /**
     * @method formatLocaleDateTime
     * @description Converts a date to an international datetime string based on locale.
     * @param dateString A string in date format that you need to convert to a specific locale.
     * @param locale A string representing the desired locale, ex. 'en-us'
     * @param options @interface DateTimeFormatOptions
     * @returns string
     */
    DateService.formatLocaleDateTime = function (date, locale, options) {
        var dateTimeFormat = new Intl.DateTimeFormat(locale, options).format(date);
        return dateTimeFormat;
    };
    /**
     * @method compareDates
     * @description Compares a calculated date that is X days in the past or future, from the given original date, against the given compare date and returns a boolean if greater or less than.
     * @param compareDate The date used to compare against the calculated date.
     * @param originalDate Original date used to calculate the new date to compare against.
     * @param daysApart Number of days in the past or future from the original date.
     * @param comparator 'greater' | 'less'| 'greater_or_equal' | 'less_or_equal' -> Will determine how to compare the compare date against the calculated date.
     * @param requestedDateInPast Is the calculated date before the original date? If true, the calculated date will be less than the original date.
     * @returns boolean
     */
    DateService.compareDates = function (compareDate, originalDate, daysApart, comparator, requestedDateInPast) {
        var calculatedDate = undefined;
        if (requestedDateInPast) {
            calculatedDate = this.removeDaysFromDate(daysApart, originalDate);
        }
        else {
            calculatedDate = this.addDaysToDate(daysApart, originalDate);
        }
        switch (comparator) {
            case 'greater':
                return compareDate > calculatedDate;
            case 'less':
                return compareDate < calculatedDate;
            case 'greater_or_equal':
                return compareDate >= calculatedDate;
            case 'less_or_equal':
                return compareDate <= calculatedDate;
            default:
                return undefined;
        }
    };
    // private methods below are used connected as formatter functions via the DateFormat enum.
    // mark as private static, wire up via the `formatters` property and then add tests for dateToString
    DateService.YYYY_MM_DD = function (date) {
        // Make attributes Zero padded
        var dateArr = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' })
            .format(date)
            .split('/')
            .map(function (n) { return (n.length === 1 ? "0".concat(n) : n); });
        // Return YYYY-MM-DD format
        return "".concat(dateArr[2], "-").concat(dateArr[0], "-").concat(dateArr[1]);
    };
    DateService.MM_DD_YYYY = function (date) {
        // Make attributes Zero padded
        var dateArr = new Intl.DateTimeFormat('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            .format(date)
            .split('/')
            .map(function (n) { return (n.length === 1 ? "0".concat(n) : n); });
        // Return MM/DD/YYYY format
        return "".concat(dateArr[0], "/").concat(dateArr[1], "/").concat(dateArr[2]);
    };
    DateService.SHORT_DATE = function (date) {
        var formatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.LONG_DATE = function (date) {
        var formatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.DATE_WITH_TIME_AND_TIMEZONE = function (date) {
        var formatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZoneName: 'short',
        };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.DD_MM_YYYY = function (date) {
        var options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    DateService.DATE_WITH_TIME = function (date) {
        var formatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: 'short',
        };
        var dateStr = new Intl.DateTimeFormat('en-US', formatOptions).format(date);
        var _a = dateStr.split(','), datePart = _a[0], time = _a[1];
        // creatre an array that looks like so...['', '7:00', 'PM', 'EST']
        var timeParts = time.split(' ');
        // Trim parts we do not need
        timeParts.pop();
        timeParts.shift();
        return "".concat(datePart, " ").concat(timeParts.join(' '));
    };
    DateService.TIME_WITH_DATE = function (date) {
        var formatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: 'short',
        };
        var dateStr = new Intl.DateTimeFormat('en-US', formatOptions).format(date);
        var _a = dateStr.split(','), datePart = _a[0], time = _a[1];
        // creatre an array that looks like so...['', '7:00', 'PM', 'EST']
        var timeParts = time.split(' ');
        // Trim parts we do not need
        timeParts.pop();
        timeParts.shift();
        return "".concat(timeParts.join(' '), " ").concat(datePart);
    };
    DateService.formatters = (_a = {},
        _a[DateFormat.YYYY_MM_DD] = DateService.YYYY_MM_DD,
        _a[DateFormat.MM_DD_YYYY] = DateService.MM_DD_YYYY,
        _a[DateFormat.SHORT_DATE] = DateService.SHORT_DATE,
        _a[DateFormat.LONG_DATE] = DateService.LONG_DATE,
        _a[DateFormat.DATE_WITH_TIME_AND_TIMEZONE] = DateService.DATE_WITH_TIME_AND_TIMEZONE,
        _a[DateFormat.DD_MM_YYYY] = DateService.DD_MM_YYYY,
        _a[DateFormat.DATE_WITH_TIME] = DateService.DATE_WITH_TIME,
        _a[DateFormat.TIME_WITH_DATE] = DateService.TIME_WITH_DATE,
        _a);
    DateService.addDaysToDate = function (daysToAdd, date) {
        var MilliSecondsInADay = 86400000;
        var targetDate = date ? date.getTime() : Date.now();
        return new Date(targetDate + daysToAdd * MilliSecondsInADay);
    };
    DateService.removeDaysFromDate = function (daysToRemove, date) {
        var MilliSecondsInADay = 86400000;
        var targetDate = date ? date.getTime() : Date.now();
        return new Date(targetDate - daysToRemove * MilliSecondsInADay);
    };
    DateService.sortStringDates = function (list, sortAsc, key) {
        return list.sort(function (a, b) {
            return sortAsc
                ? DateService.stringToDate(key ? a[key] : a, DateFormat.MM_DD_YYYY).getTime() -
                    DateService.stringToDate(key ? b[key] : b, DateFormat.MM_DD_YYYY).getTime()
                : DateService.stringToDate(key ? b[key] : b, DateFormat.MM_DD_YYYY).getTime() -
                    DateService.stringToDate(key ? a[key] : a, DateFormat.MM_DD_YYYY).getTime();
        });
    };
    DateService.datesAreSameDay = function (first, second) {
        return first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate();
    };
    return DateService;
}());
exports.DateService = DateService;
