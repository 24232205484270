/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormWaiverResultInterface = {
    id: number;
    formWaiverTitle?: string;
    formResultId: number;
    formWaiverId: number;
    content: string;
    signatureType: FormWaiverResultInterface.signatureType;
    signature: string | null;
    userIpAddress: string | null;
    insertedAt: string;
    formWaiverFile?: {
        originalFileName: string | null;
        downloadUrl: string | null;
    };
};

export namespace FormWaiverResultInterface {

    export enum signatureType {
        CHECKBOX_ACCEPT = 'checkbox_accept',
        INITIALS = 'initials',
        FULL_NAME = 'full_name',
        E_SIGNATURE = 'e-signature',
    }


}

