/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Form } from '../models/Form';
import type { FormWaiverUrl } from '../models/FormWaiverUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormsService {

    /**
     * Returns Form Waiver Download Url
     * @param id
     * @param waiverId
     * @returns FormWaiverUrl Success
     * @throws ApiError
     */
    public static getFormWaiverDownloadUrl(
        id: number,
        waiverId: number,
    ): CancelablePromise<FormWaiverUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}/waivers/{waiverId}/download-url',
            path: {
                'id': id,
                'waiverId': waiverId,
            },
        });
    }

    /**
     * Returns a form by id
     * @param id
     * @returns Form Success
     * @throws ApiError
     */
    public static showForm(
        id: number,
    ): CancelablePromise<Form> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}',
            path: {
                'id': id,
            },
        });
    }

}
