/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Checkbox } from '@teamsnap/teamsnap-ui';
import React, { useCallback } from 'react';

// Local Imports
import { PaymentExpirationModal } from 'components/PaymentExpirationModal/PaymentExpirationModal';
import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';
import { useAppDispatch, useAppNavigate } from 'state/hooks';

import { PaymentTypeOption, usePaymentHelpers } from 'state/hooks/usePaymentHelpers';
import {
  useFormIdSelector,
  useFormStateSelector,
  useOrderStateSelector,
  useOrderWaitlistStatus,
  useOrgIdSelector,
  useSkipAddonsSelector,
} from 'state/order/orderSlice';
import { makePayment, updatePaymentMethod, usePaymentResultStateSelector } from 'state/payment/paymentSlice';

import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { InstallmentTermsModal } from 'components/InstallmentTermsModal/InstallmentTermsModal';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { PaymentPreview } from 'core/api/models/PaymentPreview';
import { ACCOUNTS_URL, REGISTRATION_URL } from 'core/constants';
import ENVIRONMENT from 'core/environment';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import { DateFormat, DateService, FeatureFlagService, getFormattedAmount } from 'frontend-toolkit';
import {
  createInstallments,
  useInstallmentPreviewStateSelector,
  useInstallmentStateSelector,
  useSelectedInstallmentPlanSelector,
} from 'state/installments/installmentsSlice';
import { useUserSelector } from 'state/user/userSlice';
import { CountDownExpirationBanner } from '../CountDownExpirationBanner';
import './Payment.scss';

interface FieldState {
  value: string;
  error?: string;
}

interface Props {
  orderId?: number;
  paymentData?: PaymentPreview;
  paymentIntentClientSecret?: string | null;
  paymentOption?: string | null;
  scheduledDate?: string | null;
}

type AddonLineItems = {[k: string]: {name: string; count: number; amount: number}}

// TODO get the allowed string values on the BE, can maybe remove?
// NOTE only card payments will work at this time
const convertPaymentTypeString = (formType: string): string => {
  // convert from stripes's values to our BE options
  switch (formType) {
    case 'us_bank_account':
      return 'ach';
    case 'card':
      return 'credit_card';
    default:
      return 'credit_card';
  }
};

const getHeadingText = (paymentOption?: string | null, scheduledDate?: string | null): string => {
  const DEFAULT_HEADING = 'Checkout - Payment';
  if (paymentOption === 'pay_balance') {
    return 'Pay full balance';
  }
  if (paymentOption === 'past_due') {
    return 'Pay past due balance';
  }
  if (paymentOption === 'next_installment') {
    return scheduledDate
      ? `Pay ${DateService.dateToString(new Date(scheduledDate), DateFormat.SHORT_DATE)} installment`
      : 'Pay next installment';
  }
  return DEFAULT_HEADING;
};

export const Payment = ({ orderId, paymentData, paymentIntentClientSecret, paymentOption, scheduledDate }: Props) => {
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<FieldState>({ value: '' });
  const [lastName, setLastName] = React.useState<FieldState>({ value: '' });
  const [paymentType, setPaymentType] = React.useState<PaymentTypeOption>('card');
  const [acceptTermsChecked, setAcceptTermsChecked] = React.useState<boolean>(false);
  const [shouldUpdatePayment, setShouldUpdatePayment] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [hasCountDownEnded, setHasCountDownEnded] = React.useState(false);
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const formRef = React.useRef<HTMLFormElement>(null);
  const stripe = useStripe();
  const elements = useElements();
  const orderData = useOrderStateSelector();
  const paymentResult = usePaymentResultStateSelector();
  const installments = useInstallmentStateSelector();
  const installmentPlan = useSelectedInstallmentPlanSelector();
  const organizationId = useOrgIdSelector();
  const isWaitlistedOrder = useOrderWaitlistStatus();
  const { getFormattedAmounts, showAddonTotal } = usePaymentHelpers(paymentOption);
  const user = useUserSelector();
  const formId = useFormIdSelector();
  const formattedAmounts = getFormattedAmounts(paymentType);
  const heading = getHeadingText(paymentOption, scheduledDate);
  const installmentOptions = useInstallmentPreviewStateSelector();
  const formData = useFormStateSelector();
  const skipAddons = useSkipAddonsSelector();
  const [addonLineItems, setAddonLineItems] = React.useState<AddonLineItems>({});

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId || undefined
  );

  const isInstallmentPayment = !!installmentPlan && !!installments?.length;
  const showBackButton = !!installments || !!formId;
  const handleBackButton = () => {
    if (paymentOption) {
      window.location.href = `${ACCOUNTS_URL}/orders`;
      return;
    }

    if (formData?.availableAddons && formData?.availableAddons.length > 0 && !skipAddons) {
      navigate(`/order/${orderId}/addons`);
      return;
    }

    if (installments) {
      navigate(`/order/${orderId}/installments`);
      return;
    }
    if (formId) {
      window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
    }
  };

  const stripeNextAction = useCallback(async (paymentIntentClientSecret: string) => {
    if (!stripe) return;
    const { error } = await stripe.handleNextAction({ clientSecret: paymentIntentClientSecret });
    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
      return;
    }
  }, [stripe])

  React.useEffect(() => {
    if (!stripe || !paymentIntentClientSecret) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(paymentIntentClientSecret)
      .then(({ paymentIntent }) => {
        if (paymentIntent?.status === 'succeeded') {
          navigate(`/order/${orderId}/confirmation`)
          return;
        }
      });
  }, [stripe, paymentIntentClientSecret, orderId, navigate]);

  React.useEffect(() => {
    if (!paymentResult) return;
    if (paymentResult?.status === 'failed') {
      // TODO handle errors in UI
      setErrorMessage(paymentResult?.declineMessage || 'The payment transaction failed');
      setIsProcessing(false);
      return;
    }

    if (stripe && paymentResult?.processorStatus === 'requires_action' && paymentResult?.processorClientSecret) {
      stripeNextAction(paymentResult?.processorClientSecret);
      return;
    }

    navigate(`/order/${orderId}/confirmation`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentResult, stripeNextAction]);

  React.useEffect(() => {
    if (paymentData?.processorPaymentStatus === 'succeeded') {
      navigate(`/order/${orderId}/confirmation`);
    } else if (paymentData?.processorPaymentStatus === 'requires_action' && paymentData?.processorClientSecret) {
      stripeNextAction(paymentData?.processorClientSecret);
    }
  }, [paymentData, orderId, navigate, stripeNextAction]);

  React.useEffect(() => {
    if (!paymentOption) {
      // Group line items by name for displaying to the user instead of listing them all individually (could take up too much space)
      const updatedAddonLineItems = orderData?.orderLineItems.filter(item => item.type === 'partner_offering').reduce((acc, item) => {
        acc[item.name] = acc[item.name] || {name: item.name, count: 0, amount: 0};
        acc[item.name]['count'] += 1;
        acc[item.name]['amount'] += item.amount;
        return acc;
      }, {} as AddonLineItems) || {};

      setAddonLineItems(updatedAddonLineItems);
    }
  }, [orderData?.orderLineItems, paymentOption]);

  const submitRegistration = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!firstName.value) {
      setFirstName((prev) => ({ ...prev, error: 'First name cannot be blank' }));
    }
    if (!lastName.value) {
      setLastName((prev) => ({ ...prev, error: 'Last name cannot be blank' }));
    }

    if (!firstName.value || !lastName.value) {
      setIsProcessing(false);
      return;
    }

    // eslint-disable-next-line
    const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
      // eslint-disable-next-line
      // @ts-ignore
      elements,
      params: {
        billing_details: {
          name: `${firstName.value} ${lastName.value}`,
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    }

    if (!error && orderData) {
      dispatch(
        makePayment({
          paymentOption: paymentOption || 'next_installment',
          orderId: orderData.id,
          paymentMethodToken: stripePaymentMethod.id,
          paymentMethodType: stripePaymentMethod.type,
          returnUrl: `${window.location.origin}/order/${orderData.id}/payment${window.location.search}`,
        })
      ).then(() => {
        if (shouldUpdatePayment && orderData.paymentAccountId) {
          dispatch(
            updatePaymentMethod({
              orderId: orderData.id,
              paymentAccountId: orderData.paymentAccountId,
              paymentMethodToken: stripePaymentMethod.id,
              paymentMethodType: stripePaymentMethod.type,
              returnUrl: `${window.location.origin}/order/${orderData.id}/payment${window.location.search}`,
            })
          );
        }
      });
    }
  };

  const onChangePaymentHandler = (event: StripePaymentElementChangeEvent) => {
    setErrorMessage(undefined);
    if (!orderData?.id) return;
    const formPaymentType = event?.value?.type as PaymentTypeOption;
    if (paymentType !== formPaymentType) {
      setPaymentType(formPaymentType);

      if (orderData.status === 'pending') {
        dispatch(
          createInstallments({
            orderId: orderData.id,
            installmentPlanId: installmentPlan?.id,
            paymentMethodType: convertPaymentTypeString(formPaymentType),
          })
        );
      }
    }
  };

  return (
    <ActionContainer
      submitting={false}
      removeContentFormatting={true}
      header={
        <Header
          title={heading}
          navigation={
            showBackButton && (
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                data-testid="back-button"
                onClick={handleBackButton}
              />
            )
          }
          //   rightIcon={<CartButton />}
          profileName={`${user?.firstName} ${user?.lastName}`}
        />
      }
      footer={
        <div className="t:sui-flex t:items-center t:sui-justify-between">
          {showBackButton ? (
            <Button
              key="add-another-member"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
              icon="arrow-left"
              iconPosition="left"
              testId="back-button"
              onClick={handleBackButton}
            >
              Back
            </Button>
          ) : (
            <span />
          )}
          <Button
            key="check-out"
            color="primary"
            mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
            icon={isProcessing ? 'loader' : undefined}
            iconPosition="right"
            onClick={(e) => submitRegistration(e)}
            isDisabled={isProcessing || (isInstallmentPayment && !acceptTermsChecked) || hasCountDownEnded}
            testId="submit-and-pay-button"
          >
            Submit and Pay
          </Button>
        </div>
      }
      children={
        <div data-testid="payment-view" className="sui-px-1">
          {!paymentOption && capacityLimitsAreEnabled && orderData?.checkoutStartTime && !isWaitlistedOrder && (
            <CountDownExpirationBanner
              serverTime={orderData.currentMillis}
              checkoutStartDate={new Date(orderData.checkoutStartTime).toUTCString()}
              isCountDownEnded={hasCountDownEnded}
              onFinishCountDown={() => setHasCountDownEnded(true)}
            />
          )}

          {/* Only display if landing on page through Accept flow from invitation */}
          {installmentOptions && installmentOptions.length === 0 && isWaitlistedOrder && <OrderSummary hideSubtotal />}

          {errorMessage && (
            <div className="sui-px-3 sui-mt-2 t:sui-px-0">
              <ErrorMessage message={errorMessage} />
            </div>
          )}
          <div className="sui-bg-white sui-pb-2 sui-px-1 sui-mt-2">
            {formattedAmounts?.orgTotal && (
              <div className="sui-w-full sui-mb-0.5 sui-flex sui-justify-between sui-body sui-text-gray-60">
                <span>Organization total</span>
                <span data-testid="org-subtotal">{formattedAmounts?.orgSubTotal}</span>
              </div>
            )}
            {formattedAmounts?.fee && (
              <div className="sui-w-full sui-mb-0.5 sui-flex sui-justify-between sui-body sui-text-gray-60">
                <span>Processing Fee</span>
                <span data-testid="processing-fee">{formattedAmounts?.fee}</span>
              </div>
            )}


            {formattedAmounts?.addonTotal && showAddonTotal && (
              <>
                {formattedAmounts?.orgTotal && (
                  <>
                    <hr className="sui-my-1" />
                    <div className="sui-w-full sui-flex sui-justify-between sui-body">
                      <span className="sui-font-semibold">Amount Due: Organization</span>
                      <span data-testid="org-total">{formattedAmounts?.orgTotal}</span>
                    </div>
                  </>
                )}
                {!paymentOption && 
                  <div className="sui-mt-2">
                    {Object.values(addonLineItems).map((item, index) => (
                      <div className="sui-w-full sui-mb-0.5 sui-flex sui-justify-between sui-body sui-text-gray-60" key={`${item.name}-${index}`}>
                        <span data-testid="addon-item-name">{item.name} (x{item.count})</span>
                        <span data-testid="addon-item-amount">{getFormattedAmount(item.amount / 100)}</span>
                      </div>
                    ))}
                    <div className="sui-w-full sui-flex sui-justify-between sui-body">
                      <span className="sui-font-semibold">Amount Due: Add-ons</span>
                      <span data-testid="addon-total">{formattedAmounts?.addonTotal}</span>
                    </div>
                  </div>
                }
              </>
            )}

            {formattedAmounts?.total && (
              <>
                <hr className="sui-my-1" />
                <div className="sui-w-full sui-flex sui-justify-between sui-body">
                  <span className="sui-font-semibold">{isInstallmentPayment ? 'Amount due now:' : 'Total amount due'}</span>
                  <span data-testid="payment-total">{formattedAmounts?.total}</span>
                </div>
              </>
            )}
          </div>
          <div className="sui-bg-white sui-pb-2 sui-px-1 sui-mt-2">
            <form ref={formRef}>
              <PaymentElement
                options={{
                  layout: {
                    type: 'tabs',
                    defaultCollapsed: false,
                  }, fields: { billingDetails: { name: 'never' } }
                }}
                onChange={onChangePaymentHandler}
              />
              <div className="sui-flex sui-w-full">
                <div className="Payment--field sui-w-6/12 sui-mr-0.5">
                  <label className="Payment--label">First Name</label>
                  <div className={`Payment--input ${firstName.error && 'Payment--input-invalid'}`}>
                    <input
                      type="text"
                      inputMode="text"
                      name="firstName"
                      id="firstName"
                      placeholder="John"
                      className="Payment--input-input"
                      onChange={(e) => setFirstName({ value: e.target.value })}
                      value={firstName.value}
                    />
                    {firstName.error && <div className="Payment--error-message">{firstName.error}</div>}
                  </div>
                </div>
                <div className="Payment--field  sui-w-6/12 sui-ml-1">
                  <label className="Payment--label">Last Name</label>
                  <div className={`Payment--input ${lastName.error && 'Payment--input-invalid'}`}>
                    <input
                      type="text"
                      inputMode="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Doe"
                      className="Payment--input-input"
                      onChange={(e) => setLastName({ value: e.target.value })}
                      value={lastName.value}
                    />
                    {lastName.error && <div className="Payment--error-message">{lastName.error}</div>}
                  </div>
                </div>
              </div>
              {(paymentOption === 'next_installment' || paymentOption === 'past_due') && (
                <>
                  <div className="sui-w-full sui-flex sui-py-2">
                    <Checkbox
                      name="updatePayment"
                      inputProps={{
                        onChange: () => setShouldUpdatePayment(!shouldUpdatePayment),
                      }}
                      label="Update current payment method"
                    />
                  </div>
                  <InstallmentTermsModal show={isModalOpen} closeFn={() => setIsModalOpen(false)} />
                </>
              )}
              {isInstallmentPayment && (
                <>
                  <div className="sui-w-full sui-flex sui-py-2">
                    <Checkbox
                      name="agree"
                      inputProps={{
                        onChange: () => setAcceptTermsChecked(!acceptTermsChecked),
                      }}
                      label={
                        <>
                          I agree to the{' '}
                          <button
                            className="Payment--link-text"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(true);
                            }}
                          >
                            installment plan terms
                          </button>
                        </>
                      }
                    />
                  </div>
                  <InstallmentTermsModal show={isModalOpen} closeFn={() => setIsModalOpen(false)} />
                </>
              )}
            </form>
          </div>
          {capacityLimitsAreEnabled && (
            <PaymentExpirationModal
              isOpen={hasCountDownEnded}
              onClose={() => {
                window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
              }}
            />
          )}
        </div>
      }
    />
  );
};
