/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstallmentsService, OrdersService } from 'core/api';
import { AsyncState } from 'core/types';
import { getOrderConfirmation } from 'state/order/orderSlice';

import { asyncFulfilledSubReducer, asyncPendingReducer, handleErrors, useAppSelector } from '../hooks';
import { InstallmentsState } from './types';

const initialState: AsyncState<InstallmentsState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

const PAY_NOW = -1;
const PAY_OFFLINE = -2;

export const getInstallmentOptions = createAsyncThunk('order/installmentOptions', (orderId: number) =>
  handleErrors(() => InstallmentsService.previewInstallmentPlanOptions(orderId))
);

export const createInstallments = createAsyncThunk(
  'order/createInstallments',
  ({
    orderId,
    installmentPlanId,
    paymentMethodType = 'credit_card',
  }: {
    orderId: number;
    installmentPlanId?: number;
    paymentMethodType?: string;
  }) => handleErrors(() => OrdersService.createInstallments(orderId, { installmentPlanId, paymentMethodType }))
);

export const installmentsSlice = createSlice({
  name: 'installments',
  initialState,
  reducers: {
    updateSelectedPlan: (state, action: PayloadAction<number>) => {
      if (action.payload === PAY_NOW) {
        const newState = {
          ...state.data,
          selectedPayNow: true,
        };
        state.data = newState;
      }

      if (action.payload === PAY_OFFLINE) {
        const newState = {
          ...state.data,
          selectedPayOffline: true,
        };
        state.data = newState;
      }

      const selectedOption = state.data?.installmentOptions?.find(
        (option) => option.installmentPlan.id === action.payload
      );

      if (selectedOption) {
        const newState = {
          ...state.data,
          selectedPlan: selectedOption.installmentPlan,
        };
        state.data = newState;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstallmentOptions.pending, asyncPendingReducer);

    builder.addCase(getInstallmentOptions.fulfilled, (state, action) =>
      // @ts-ignore
      asyncFulfilledSubReducer(state, action, 'installmentOptions')
    );

    builder.addCase(createInstallments.fulfilled, (state, action) =>
      // @ts-ignore
      asyncFulfilledSubReducer(state, action, 'installments')
    );
    builder.addCase(getOrderConfirmation.fulfilled, (state, action) => {
      const updatedState = {
        ...state.data,
        installments: action.payload.data?.installments,
      };

      state.data = updatedState;

      if (action.payload.error) {
        state.error = true;
        state.errors = action.payload.errors || ['Unknown Error'];
      }

      state.processing = false;
    });
  },
});

export const { updateSelectedPlan } = installmentsSlice.actions;

export const useInstallmentStateSelector = () => useAppSelector((state) => state.installments?.data?.installments);
export const useInstallmentPreviewStateSelector = () =>
  useAppSelector((state) => state.installments?.data?.installmentOptions);
export const useSelectedInstallmentPlanSelector = () =>
  useAppSelector((state) => state.installments?.data?.selectedPlan);
export const useSelectedPayNowSelector = () => useAppSelector((state) => state.installments?.data?.selectedPayNow);
export const useSelectedPayOfflineSelector = () => useAppSelector((state) => state.installments?.data?.selectedPayOffline);

export default installmentsSlice.reducer;
