/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FormResultWaitlistStatus {
    WAITLISTED = 'waitlisted',
    OFFER_EXTENDED = 'offer_extended',
    OFFER_ACCEPTED = 'offer_accepted',
    ENROLLED = 'enrolled',
    RELEASED = 'released',
}
