import { ampli } from '../../core/ampli';

export enum AMPLITUDE_SCREEN_NAMES {
  'installments' = 'Registrant Choose Installment Plan',
  'payment' = 'Registrant Payment Method',
  'confirmation' = 'Registrant Confirmation Message',
  'addons' = 'Registrant Add-ons',
}

interface AmplitudeTrackActions {
  screenViewed: (path: string | undefined, orderId: string | undefined) => void;
}

// Define the amplitude functions available for use through the hook
export const useAmplitude = () => {
  // Exposing method to identify user in Amplitude
  const amplitudeIdentify = (uuid: string, email: string) => {
    ampli.identify(uuid, {
      email: email,
    });
  };

  // Exposing methods to track events in Amplitude
  const amplitudeTrack: AmplitudeTrackActions = {
    // Tracks which page pathname the user is viewing
    screenViewed: (path: string | undefined, orderId: string | undefined) => {
      // we just want to track these 3 pages from the registrant flow for now
      if (path && ['payment', 'installments', 'confirmation', 'addons'].indexOf(path) >= 0) {
        const matchedAmplitudeScreenName = AMPLITUDE_SCREEN_NAMES[path as keyof typeof AMPLITUDE_SCREEN_NAMES];
        if (matchedAmplitudeScreenName && orderId) {
          ampli.screenViewed({
            screen_name: matchedAmplitudeScreenName,
            url: `/order/${orderId}/${path}`,
          });
        }
      }
    },
  };

  return { amplitudeIdentify, amplitudeTrack };
};
