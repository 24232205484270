import { Banner } from 'components/shared/Banner';
import { CHECKOUT_EXPIRATION_MINUTES } from 'core/constants';
import { CountDownExpiration } from './CountDownExpiration';
import { useClockOffsetSelector } from '../../state/order/orderSlice';

interface CountDownExpirationBannerProps {
  checkoutStartDate: string;
  isCountDownEnded: boolean;
  onFinishCountDown: () => void;
  serverTime: number;
}

export const CountDownExpirationBanner = ({
  checkoutStartDate,
  isCountDownEnded,
  onFinishCountDown,
  serverTime,
}: CountDownExpirationBannerProps) => {
  const clockOffset = useClockOffsetSelector() || 0;

  const getTargetDate = () => {
    const checkOutTime = new Date(checkoutStartDate).getTime();
    const expiredMillis = CHECKOUT_EXPIRATION_MINUTES * 60 * 1000;

    if (serverTime - checkOutTime > expiredMillis) {
      onFinishCountDown();
      return 0;
    }

    return checkOutTime + expiredMillis - clockOffset;
  };

  return (
    <Banner
      rounded={true}
      warning={true}
      classes="sui-mt-2 u-padSm"
      children={
        <div className="sui-flex sui-items-center">
          <CountDownExpiration
            targetDate={getTargetDate()}
            isCountDownEnded={isCountDownEnded}
            onFinishCountDown={onFinishCountDown}
          />
          <div className="sui-flex-col sui-items-start">
            <div className="sui-font-size-6">Time Remaining</div>
            <div className="sui-font-size-4 sui-mt-1">
              Reserved spots will be released when this checkout session expires.
            </div>
          </div>
        </div>
      }
    />
  );
};
