"use strict";
/**
 * Emulate CSS's native transition timing functions.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function
 * https://easings.net/
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Easing = void 0;
var Easing = {
    linear: function (t) { return t; },
    easeIn: function (x) { return x * x * x; },
    easeOut: function (x) { return 1 - Math.pow(1 - x, 3); },
    easeInOut: function (x) { return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2; },
};
exports.Easing = Easing;
