import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { createNextPayment, usePaymentStateSelector } from 'state/payment/paymentSlice';
import { useOrderStateSelector } from 'state/order/orderSlice';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeElementsOptionsMode } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { STRIPE_CLIENT_KEY } from 'core/constants';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { Payment } from 'components/Payment/Payment';
import {
  useInstallmentPreviewStateSelector,
  useSelectedInstallmentPlanSelector,
  useSelectedPayNowSelector,
} from 'state/installments/installmentsSlice';

const stripePromise = loadStripe(STRIPE_CLIENT_KEY, {
  betas: ['elements_enable_deferred_intent_beta_1'],
});

const PaidNotice = () => <div>Order is fully paid</div>; // TODO this is just to prevent errors, may need design input

const PaymentPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [search] = useSearchParams();
  const paymentOption = search.get('option');
  const scheduledDate = search.get('scheduledDate');
  const paymentIntentClientSecret = search.get('payment_intent_client_secret');
  const paymentData = usePaymentStateSelector();
  const orderData = useOrderStateSelector();
  const installmentOptions = useInstallmentPreviewStateSelector();
  const selectedPlan = useSelectedInstallmentPlanSelector();
  const selectedPayNow = useSelectedPayNowSelector();
  const navigate = useAppNavigate();

  const isPendingPayment = orderData?.status === 'pending_payment';
  const isPaid = orderData?.status === 'fully_paid';

  const notSelected = () => !selectedPlan && !selectedPayNow;

  // a selection is needed to get correct payment amount
  const needToSelectInstallmentPlan =
    !isPendingPayment &&
    ((installmentOptions && installmentOptions?.length > 0 && notSelected()) ||
      (orderData?.allowOfflineCheckout && notSelected()));

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (orderId && !isPaid) {
      if (needToSelectInstallmentPlan && !paymentOption && !paymentIntentClientSecret) {
        navigate(`/order/${orderId}/installments`);
      } else {
        dispatch(
          createNextPayment({ orderId: +orderId, paymentOption: paymentOption ? paymentOption : 'next_installment' })
        );
      }
    }
  }, [needToSelectInstallmentPlan, orderId, isPaid, dispatch, navigate, paymentOption, paymentIntentClientSecret]);

  const paymentAmountWithFees = () => {
    if (paymentData) {
      return paymentData.paymentAccountPaysProcessingFee ? paymentData.amount : paymentData.amount + paymentData.creditCardFee;
    } else {
      return 1;
    }
  };

  const options: StripeElementsOptionsMode = {
    mode: 'payment',
    amount: paymentAmountWithFees(),
    currency: paymentData?.currency || 'usd',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    payment_method_options: {
      us_bank_account: {
        verification_method: 'instant_or_skip',
        financial_connections: { permissions: ['payment_method'] },
      },
    },
  };

  if (paymentData?.processorExternalAccountId && paymentData?.processorAccountIsMerchant) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options.onBehalfOf = paymentData?.processorExternalAccountId;
  }

  if (isPaid) {
    return <PaidNotice />;
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      <Payment
        orderId={orderId ? +orderId : undefined}
        paymentData={paymentData}
        paymentIntentClientSecret={paymentIntentClientSecret}
        {...{ paymentOption, scheduledDate }}
      />
    </Elements>
  );
};

export default PaymentPage;
