import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import * as validations from 'core/validations';

import { ActionContainer } from 'components/shared/ActionContainer';
import { FormField, FormFieldType } from 'components/shared/FormField/FormField';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { updateLogin, useLoginEmailSelctor, userExists } from 'state/user/userSlice';

import logoImage from '../../assets/images/logo.svg';

const schema = Yup.object({
  email: validations.EMAIL,
});

interface Values {
  email: string;
}

export const Email = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const location = useLocation();
  const email = useLoginEmailSelctor();

  const onSubmit = async (values: Values, { setFieldError, setSubmitting }: FormikHelpers<Values>) => {
    const result = await dispatch(userExists(values.email)).unwrap();

    if (result.error) {
      setSubmitting(false);
      setFieldError(
        'email',
        'Oh no! It looks like something went wrong. Please try again or contact TeamSnap Support for help.'
      );
    } else {
      dispatch(updateLogin({ loginEmail: values.email }));
      if (location.pathname.indexOf('accept') > -1 || location.pathname.indexOf('decline') > -1) {
        navigate(`${location.pathname}/${result.data?.exists ? 'login' : 'newUser'}`);
      } else {
        navigate(`/order/${orderId}/${result.data?.exists ? 'login' : 'newUser'}`);
      }
    }
  };

  return (
    <div className="EmailEntry">
      <Formik
        initialValues={{
          email: email || '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer action={handleSubmit} actionLabel="Next" title="Order - login" submitting={isSubmitting}>
            <Form>
              <div className="sui-flex sui-justify-center sui-mb-3">
                <img className="logo-image" src={logoImage} alt="TeamSnap" />
              </div>

              <h1 className="sui-heading-md">Order Login</h1>

              <p className="sui-body sui-text-gray-8 sui-mb-3">
                Please enter your TeamSnap account email to get started.
              </p>

              <FormField type={FormFieldType.EMAIL} label="Email" name="email" placeholder="email@email.com" />
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
