import * as React from 'react';
import { FieldGroup, FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';
import { Field, useField } from 'formik';
import { PasswordToggleInput, DateTimePicker } from 'frontend-toolkit';
import { RadioGroup } from '../RadioGroup/RadioGroup';

export enum FieldType {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  BIRTHDATE = 'birthdate',
  GENDER = 'gender',
  RELATION = 'relation',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  PHONE = 'phone',
  MULTI_SELECT = 'multi-select',
}

export enum FormFieldType {
  CHECKBOX = 'checkbox',
  DATETIME = 'datetime',
  EMAIL = 'email',
  PASSWORD_INPUT = 'input',
  PASSWORD = 'password',
  SELECT = 'select',
  TEXT = 'text',
  RADIO = 'radio',
  TEXTAREA = 'textarea',
}

type SelectOptions = {
  label: string;
  value: string;
};

type RadioOptions = {
  label: string;
  value: string;
};

export interface Props {
  label?: string | React.ReactNode;
  mods?: string;
  name: string;
  options?: SelectOptions[] | RadioOptions[];
  placeholder?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  multiselect?: boolean;
  type:
    | FormFieldType.CHECKBOX
    | FormFieldType.DATETIME
    | FormFieldType.EMAIL
    | FormFieldType.PASSWORD_INPUT
    | FormFieldType.PASSWORD
    | FormFieldType.TEXT
    | FormFieldType.RADIO
    | FormFieldType.SELECT
    | FormFieldType.TEXTAREA;
}

export const FormField = ({ label, ...props }: Props) => {
  const [field, meta, helpers] = useField(props);
  const { value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;
  const { mods, name, options, type, disabled } = props;

  const hasError = touched && error;
  const classes = ['Input', hasError ? 'sui-border-red-4' : '', mods].join(' ');

  const textAreaClasses = [
    'sui-form-control',
    'sui-block',
    'sui-p-1',
    'sui-w-full',
    'sui-text-sm',
    'sui-rounded',
    'sui-text-gray-9',
    hasError ? 'sui-border-red-4' : 'sui-border-gray-5',
  ];

  const renderOptionType = (multiselect: boolean) => {
    if (multiselect) {
      return options?.map((option) => (
        <div key={option.value} className="Checkbox">
          <Field className="Checkbox-input" type="checkbox" name={name.toString()} value={option.value} />
          <label className="Checkbox-label sui-body" htmlFor={name.toString()}>
            {option.label}
          </label>
        </div>
      ));
    }

    return (
      <select
        className={`SelectBox-options sui-font-size-5 ${classes}`}
        style={{ height: 48 }}
        id={name}
        {...field}
        disabled={disabled}
      >
        <option value="">Select</option>
        {options?.map((option) => (
          <option key={option.value} label={option.label} {...field} {...props} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  const renderType = () => {
    let theDate;

    if (FormFieldType.DATETIME) {
      theDate = new Date(value);
      theDate = new Date(theDate.getUTCFullYear(), theDate.getUTCMonth(), theDate.getUTCDate());
    }

    const isMultiSelect = props?.multiselect ?? false;

    switch (type) {
      case FormFieldType.CHECKBOX:
        return (
          <div className="Checkbox Checkbox--inline">
            <input className="Checkbox-input" id={name} {...field} {...props} />
            <label className="Checkbox-label sui-body" htmlFor={name}>
              {label}
            </label>
          </div>
        );

      case FormFieldType.SELECT:
        return (
          <div className={!props?.multiselect ? 'SelectBox' : ''}>
            <label htmlFor={name} className="sui-label">
              {label}
            </label>

            {renderOptionType(isMultiSelect)}
          </div>
        );

      case FormFieldType.RADIO:
        return <RadioGroup label={''} name={name} options={options} />;

      case FormFieldType.DATETIME:
        return (
          <>
            <label htmlFor={name} className="sui-label">
              {label}
            </label>

            <div>
              <DateTimePicker
                id={`DateTimePicker--${name}`}
                datetime={value ? theDate : null}
                dateFormat="P"
                inputClasses=""
                placeholderText="Select Date"
                onChange={(datetime) => setValue(datetime?.toISOString())}
                onBlur={() => {
                  if (typeof value === 'undefined') {
                    setValue('');
                  }

                  setTouched(true);
                }}
                disabled={disabled}
                containerClasses={`sui-px-1 ${hasError ? 'sui-border-red-4' : ''}`}
              />
            </div>
          </>
        );

      case FormFieldType.PASSWORD_INPUT:
        return (
          <>
            <label htmlFor={name} className="sui-label">
              {label}
            </label>
            <PasswordToggleInput hasError={hasError} {...field} {...props} />
          </>
        );

      case FormFieldType.TEXTAREA:
        return (
          <>
            <label htmlFor={name} className="sui-label">
              {label}
            </label>

            <div
              className="sui-py-1 sui-block"
              style={{
                height: 80,
                marginBottom: 10, // Needed to add margin for error messages below text area
              }}
            >
              <textarea
                {...field}
                {...props}
                name={name}
                className={textAreaClasses.join(' ')}
                style={{
                  height: 80,
                  resize: 'none',
                  fontSize: '1rem',
                  fontFamily: 'Lato',
                }}
                placeholder="Text"
              />
            </div>
          </>
        );

      default: {
        let placeholderText = 'Text';

        if (type === FormFieldType.EMAIL) {
          placeholderText = '123@gmail.com';
        }

        if (label?.toString().includes('Phone')) {
          placeholderText = '123-333-3333';
        }

        return (
          <>
            <label htmlFor={name} className="sui-label">
              {label}
            </label>

            <input
              className={`${classes} sui-h-6 sui-px-1 sui-py-1 sui-body`}
              id={name}
              {...field}
              {...props}
              placeholder={placeholderText}
            />
          </>
        );
      }
    }
  };

  return (
    <FieldGroup mods={`u-sizeFull sui-mt-2 ${mods || ''}`}>
      {renderType()}

      {hasError ? (
        <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
          {error}
        </FieldMessage>
      ) : null}
    </FieldGroup>
  );
};
