import { getFormattedAmount } from 'frontend-toolkit';
import { usePaymentResultStateSelector } from 'state/payment/paymentSlice';

export const usePaymentResultHelpers = () => {
  const paymentData = usePaymentResultStateSelector();
  const subtotal = paymentData?.amount ? getFormattedAmount(paymentData?.amount / 100) : '';
  const total = paymentData?.chargedAmount ? getFormattedAmount(paymentData?.chargedAmount / 100) : '';
  const fee = paymentData?.appliedFeeAmount ? getFormattedAmount(paymentData?.appliedFeeAmount / 100) : '';
  const showFee = !paymentData?.paymentAccountPaysProcessingFee;
  const paymentMethod = paymentData?.paymentMethod;

  return {
    total,
    subtotal,
    fee,
    showFee,
    paymentMethod,
  };
};
