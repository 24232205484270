import { PayloadAction } from '@reduxjs/toolkit';
import { AsyncState, Response } from 'core/types';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import type { AppDispatch, RootState } from '../store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppNavigate = useNavigate;

export function asyncFulfilledReducer<T>(state: AsyncState<T>, action: PayloadAction<Response<T>>) {
  state.data = action.payload.data;
  state.error = action.payload.error;
  state.errors = action.payload.errors;
  state.processing = false;
}

export function asyncFulfilledSubReducer<T>(
  state: AsyncState<T>,
  action: PayloadAction<Response<T[keyof T]>>,
  key: keyof T
) {
  const updatedState = {
    ...state.data,
    [key]: action.payload.data,
  } as T;

  state.data = updatedState;
  state.error = action.payload.error;

  if (action.payload.error) {
    state.errors = action.payload.errors || ['Unknown Error'];
  }

  state.processing = false;
}

// eslint-disable-next-line
export function asyncFulfilledNoDataReducer(state: AsyncState<any>, action: PayloadAction<Response<any>>) {
  state.error = action.payload.error;
  if (action.payload.error) {
    state.errors = action.payload.errors || ['Unknown Error'];
  }
  state.processing = false;
}

export function asyncPendingReducer<T>(state: AsyncState<T>, action: PayloadAction<void>) {
  state.error = false;
  state.errors = [];
  state.processing = true;
}

export async function handleErrors<T>(call: () => Promise<T>): Promise<Response<T>> {
  try {
    const data = await call();
    return { data, error: false, errors: [] };
    // eslint-disable-next-line
  } catch (e: any) {
    if (e.status === 403) return { data: null, error: false, errors: [] };

    if (!window.navigator.onLine) {
      return {
        data: null,
        error: true,
        errors: ["Internet connectivity is low, you can trying running your device's internet troubleshooter"],
      };
    }

    if (e.message === 'Internal Server Error') {
      return {
        data: null,
        error: true,
        errors: ['Oh no! It looks like something went wrong. Please try again or contact TeamSnap Support for help.'],
      };
    }

    return { data: null, error: true, errors: e.body || [e.message] || [e] };
  }
}

// eslint-disable-next-line
export function mapSubState(state: AsyncState<any>, action: PayloadAction<Response<any>>, key: string) {
  const updatedState = {
    ...state.data,
    [key]: action.payload.data,
  };

  state.data = updatedState;

  if (action.payload.error) {
    state.error = true;
    state.errors = action.payload.errors || ['Unknown Error'];
  }

  state.processing = false;
}

export function modifySubState(
  // eslint-disable-next-line
  state: AsyncState<any>,
  // eslint-disable-next-line
  action: PayloadAction<Response<any>>,
  // eslint-disable-next-line
  newState: Record<string, any>
) {
  const updatedState = {
    ...state.data,
    ...newState,
  };

  state.data = updatedState;

  if (action.payload.error) {
    state.error = true;
    state.errors = action.payload.errors || ['Unknown Error'];
  }

  state.processing = false;
}
