import { ActionContainer } from 'components/shared';
import React from 'react';

const NotFound = () => (
  <ActionContainer contentStyles={{ backgroundColor: '#fff' }}>
    <div className="sui-flex sui-flex-col sui-mx-2 sui-items-center sui-py-6">
      <div className="sui-mt-2 sui-font-size-6 sui-text-center">Nothing to see here </div>
    </div>
  </ActionContainer>
);
export default NotFound;
