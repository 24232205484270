import { FeatureFlagConfigModel } from 'frontend-toolkit';

export enum FeatureFlags {
  PARTICIPANT_GROUP_RESTRICTION = 'PARTICIPANT_GROUP_RESTRICTION',
  PARTICIPANT_GROUP_CAPACITY_LIMIT = 'PARTICIPANT_GROUP_CAPACITY_LIMIT',
  PARTICIPANT_GROUP_WAITLIST = 'PARTICIPANT_GROUP_WAITLIST',
  COUPON_CODES = 'COUPON_CODES',
  AUTOMATIC_DISCOUNTS = 'AUTOMATIC_DISCOUNTS',
  OFFLINE_PAYMENTS = 'OFFLINE_PAYMENTS',
}

export const FeatureFlagConfig: Record<FeatureFlags, FeatureFlagConfigModel> = {
  [FeatureFlags.PARTICIPANT_GROUP_RESTRICTION]: {
    id: FeatureFlags.PARTICIPANT_GROUP_RESTRICTION,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  },
  [FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT]: {
    id: FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  },
  [FeatureFlags.PARTICIPANT_GROUP_WAITLIST]: {
    id: FeatureFlags.PARTICIPANT_GROUP_WAITLIST,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  },
  [FeatureFlags.COUPON_CODES]: {
    id: FeatureFlags.COUPON_CODES,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  },
  [FeatureFlags.AUTOMATIC_DISCOUNTS]: {
    id: FeatureFlags.AUTOMATIC_DISCOUNTS,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  },
  [FeatureFlags.OFFLINE_PAYMENTS]: {
    id: FeatureFlags.OFFLINE_PAYMENTS,
    enabled: false,
    allowedEnvironments: ['staging', 'local'],
    allowedOrgsInProd: [36471, 36681, 37503],
  }
};
