/* eslint-disable @typescript-eslint/ban-ts-comment */

import Addons from 'components/Addons/Addons';

const AddonsPage = () => {
  return (
    <Addons />
  );
}

export default AddonsPage;