/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormResultInterface, HouseholdPerson, OrderLineItem } from 'core/api';
import { getFormattedAmount } from 'frontend-toolkit';
import './OrderLineItems.scss';

export const OrderLineItems = ({
  lineItems,
  householdPeople,
  formResults,
}: {
  lineItems: OrderLineItem[];
  householdPeople?: HouseholdPerson[];
  formResults?: FormResultInterface[];
}) => {
  if (!lineItems) return <div />;

  // Group order line items by householdPersonId (participant household person id) and then by form result sourceId
  const groupByParticipant = (): Record<number, Record<number, OrderLineItem[]>> => {
    return lineItems.reduce((acc, item) => {
      if (item.sourceType === 'form_result') {
        // these are only registration fees or adjustment fees (negative or positive) - no discounts included
        const sourceId = item.sourceId as number;
        const result = formResults?.find((r) => r.id === sourceId);
        const householdPersonId = result?.householdPersonId;

        if (householdPersonId !== undefined) {
          if (!acc[householdPersonId]) {
            acc[householdPersonId] = {};
          }

          if (!acc[householdPersonId][sourceId]) {
            acc[householdPersonId][sourceId] = [];
          }

          acc[householdPersonId][sourceId].push(item);
        }
      }
      return acc;
    }, {} as Record<number, Record<number, OrderLineItem[]>>);
  };

  const getDisplayName = (householdPersonId: number) => {
    const householdPerson = householdPeople?.find((p) => p.id === householdPersonId);
    return householdPerson?.person ? `${householdPerson.person.firstName} ${householdPerson.person.lastName}` : '';
  };

  const groupedItems = groupByParticipant();

  // total for a participant including all of the different registrations (form results)
  const calcTotal = (lineItemsByFormResult: Record<number, OrderLineItem[]>) => {
    let total = Object.entries(lineItemsByFormResult)
      .flatMap(([formResultId, items]) => [
        ...items,
        ...items.flatMap((item) => getDiscountsForLineItem(item, formResultId)),
      ])
      .reduce((a, i) => a + i.amount, 0);
    total = Math.sign(total) === -1 ? 0 : total;
    return total;
  };

  const discountMap = lineItems
    .filter((item) => item.externalType === 'automatic_discount' || item.externalType === 'coupon_code_discount')
    .reduce((acc, lineItem) => {
      const key = `${lineItem.sourceId}-${lineItem.sourceType}-${lineItem.scopeId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(lineItem);
      return acc;
    }, {} as { [key: string]: OrderLineItem[] });

  const getDiscountsForLineItem = (item: OrderLineItem, formResultId: string) => {
    const key = `${item.externalId}-${item.externalType}-${formResultId}`;
    return discountMap[key] || [];
  };

  const getDiscountsForLineItemName = (item: OrderLineItem, formResultId: string) => {
    return getDiscountsForLineItem(item, formResultId)
      .map((discount) => `${discount.name} (${getFormattedAmount(discount.amount / 100)})`)
      .join(', ');
  };

  const getDiscountsForLineItemTotal = (item: OrderLineItem, formResultId: string) => {
    return getDiscountsForLineItem(item, formResultId).reduce((accumulator, curr) => {
      return (accumulator = accumulator + Math.abs(curr.amount));
    }, 0);
  };

  return (
    <>
      {Object.entries(groupedItems).map(([householdPersonId, lineItemsByFormResult]) => (
        <div
          className="sui-bg-white sui-py-2 sui-px-3 sui-mt-2 sui-shadow-up sui-rounded"
          data-testid="participant-registration-card"
        >
          <div className="sui-flex sui-align-items sui-justify-between sui-mb-2">
            <h2 className="sui-body sui-font-bold">{getDisplayName(+householdPersonId)}</h2>
          </div>
          <hr className="sui-my-2 sui-border-accent-background-weak/50" />
          {Object.entries(lineItemsByFormResult).map(([formResultId, lineItems]) => (
            <>
              <div key={formResultId}>
                {lineItems.map((orderLineItem) => (
                  <>
                    <div
                      key={`${formResultId}_${orderLineItem.name}`}
                      className="sui-flex sui-justify-between sui-body sui-py-0.5 sui-text-gray-7"
                      data-testid="participant-registration-item"
                    >
                      <div className="sui-flex-1">
                        <div data-testid="item-title">{orderLineItem.name}</div>
                      </div>

                      <div
                        className={`sui-text-right sui-ml-2 ${
                          orderLineItem.type === 'credit' ? 'sui-text-red' : 'sui-text-gray-9'
                        }`}
                      >
                        <span data-testid="order-line-item-final-amount">
                          {getFormattedAmount(
                            (orderLineItem.amount - getDiscountsForLineItemTotal(orderLineItem, formResultId)) / 100
                          )}
                        </span>
                      </div>
                    </div>
                    {getDiscountsForLineItem(orderLineItem, formResultId).length > 0 && (
                      <div
                        key={`${formResultId}_${orderLineItem.name}_discount`}
                        className="sui-flex sui-justify-between sui-body sui-py-0.5 sui-text-gray-6"
                        data-testid="discount-member-cart-item"
                      >
                        <div className="sui-flex-1" data-testid="discounts-fee-title">
                          {getDiscountsForLineItemName(orderLineItem, formResultId)}
                        </div>

                        <div className="sui-flex sui-items-center">
                          <div className="sui-text-right sui-ml-2 sui-line-through">
                            <span data-testid="order-line-item-original-amount">
                              {getFormattedAmount(orderLineItem.amount / 100)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <hr className="sui-my-2 sui-border-accent-background-weak/50" />
            </>
          ))}
          <div className="sui-flex sui-justify-between sui-body sui-font-bold">
            <span>Participant Total</span>
            <span data-testid={`participant-total-${householdPersonId}`}>
              {getFormattedAmount(calcTotal(lineItemsByFormResult) / 100)}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};
