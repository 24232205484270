/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FormResultStatus {
    IN_PROGRESS = 'in_progress',
    SUBMITTED = 'submitted',
    IN_CART = 'in_cart',
    CANCELED = 'canceled',
    DELETED = 'deleted',
    CHECKOUT = 'checkout',
}
